import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Clinic, CLINIC_COLORS } from 'src/app/class/clinic/clinic';
import { Password } from 'src/app/class/session/session';
import Validation from 'src/app/class/validations/validation';
import { SessionService } from 'src/app/service/session.service';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {


  form: FormGroup;
  submitted = false;


  emailFromInvitation = "";



  public clinics: Observable<Clinic[]>;

  constructor(private formBuilder: FormBuilder, private sessionService: SessionService,
    private route: ActivatedRoute,

    private alertViewService: AlertViewService,
    private db: AngularFirestore,

  ) {




    this.clinics = this.db
      .collection<Clinic>('clinics', ref => { return ref.where('createdByVisitorList', '==', false) })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(action => {
          // 日付をセットしたコメントを返す
          const data = action.payload.doc.data();
          const clinicData = new Clinic(
            data.name,
            data.event_color
          );
          clinicData.setId(action.payload.doc.id)
          clinicData.setCreatedByVisitorList(data["createdByVisitorList"])
          if (environment.debug) {
            console.log(clinicData)
          }
          return clinicData;
        })));


  }

  ngOnInit(): void {

    if (this.route.snapshot.paramMap.get('id') !== "default") {
      // document.getElementById("emailelement")["readOnly"] = true;
      this.emailFromInvitation = this.route.snapshot.paramMap.get('id')
    }

    this.form = this.formBuilder.group(
      {
        fullname: ['', Validators.required],

        email: [this.emailFromInvitation, [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,30}'
            )
          ]
        ],
        confirmPassword: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue]
      },
      {
        validators: [Validation.match('password', 'confirmPassword')]
      }
    );


  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(clinics): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }


    this.selectClinicModal(clinics);

  }

  clinicMap = new Map()
  selectClinicModal(clinics) {

    const invalid = "999999999999999";

    this.clinicMap.set(invalid, "選択する");

    clinics.forEach(clinic => {
      if (clinic.name.length > 0 && !clinic.createdByVisitorList) {
        this.clinicMap.set(clinic.id, clinic.name);

      }
    });



    Swal.fire({
      title: '所属事業所を選択してください',
      input: 'select',
      inputOptions: {
        'クリニック名': this.clinicMap
      },
      showCancelButton: true,
      showConfirmButton: true,
      showDenyButton: true,
      denyButtonText: "新規登録",
      cancelButtonText: "キャンセル",
      confirmButtonColor: '#28A745',
      denyButtonColor: '#FFC107',

      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === invalid) {
            this.selectClinicModal(clinics);
            resolve("");
          } else {
            this.gotoNextPage(value);
            resolve("");
          }
        })
      }
    }).then((result) => {
      if (result.isDenied) this.showNewClinicAlert(clinics);
    })

  }


  showNewClinicAlert(clinics) {
    var createdId = "";
    Swal.fire({
      title: '新しい事業所名を入力',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: '登録',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#28A745',
      cancelButtonText: "キャンセル",

      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value.length < 3) {
            resolve('正しいクリニック名を入力してください')

          } else if (clinics.find((item) => {
            return item.name === value
          })) {
            resolve('この事業所名はすでに使われています')

          } else {

            const id = this.db.createId();
            this.db.collection("clinics").doc(id).set({
              event_color: CLINIC_COLORS[Math.floor(Math.random() * CLINIC_COLORS.length)],
              id: id,
              name: value
            });
            this.db.collection("clinics").doc(id).collection("selections").doc("patient").set({

            });
            this.db.collection("clinics").doc(id).collection("selections").doc("event").set({

            });

            this.clinicMap.set(id, value);
            createdId = id
            resolve(null);
          }
        })
      }

    }).then((result) => {
      if (result.isConfirmed) {
        this.gotoNextPage(createdId);
      }
    })
  }



  gotoNextPage(value) {

    var account = new Password(this.form.value);

    account.clinic_id = value;
    account.clinic_name = this.clinicMap.get(value);

    this.sessionService.passSignUpValues(account);
    if (environment.debug) {
      console.log(JSON.stringify(value, null, 2));
    }

  }
}
