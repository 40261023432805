export class Selection {

}

export const SELECCTIONS_PATIENT = {
    "介護度": [
    ],
    "主治医": [
    ],
    "往診開始理由": [
    ],
    "かかりつけ病院": [
    ],
    "その他病名": [
    ],
    "既往歴": [
    ],
    "家族歴": [
    ],
    "アレルギー・禁忌": [
    ],
    "感染症": [
    ],
    "連絡先": [
    ],
    "訪問看護ステーション": [
    ],
    "訪問リハビリ": [
    ],
    "ケアマネージャ": [
    ],
    "医療保険情報": [
    ],
    "介護保険情報": [
    ],
    "管理加算": [
    ],
    "定期処置": [
    ],
    "デイサービス": [
    ],
    "その他要介護状況": [
    ],
    "訪問薬剤": [
    ],
    "性別": [
    ],
    "薬剤調達方法": [
    ],
    "入浴": [
    ],
    "食事": [
    ],
    "内服": [


    ],
    "排泄": [


    ],
    "移動": [


    ],
    "拘縮": [


    ],
    "認知機能低下": [


    ],
    "難聴": [


    ],
    "視覚障害": [


    ],
    "頓用薬": [


    ],
    "ワクチン接種": [


    ],
}




export const SELECCTIONS_EVENT = {

    "処置内容": [


    ],
    "持ち込み物品": [


    ],
    "実施物品": [


    ],
}

