import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterParent'
})
export class FilterParentPipe implements PipeTransform {


  transform(items: any[], col: string, value: string): any {

    console.log(items);
    console.log(col);
    console.log(value);

    if (value == undefined) return items;


    return items.filter((x) => {
      if (x["フリガナ"]) {
        return (x["name"].indexOf(value) != -1) || (this.kanaToHira(x["フリガナ"]).indexOf(value) != -1) || (this.hiraToKana(x["フリガナ"]).indexOf(value) != -1)
      } else {
        return (x["name"].indexOf(value) != -1)
      }
    });
  }


  kanaToHira(str) {
    return str.replace(/[\u30a1-\u30f6]/g, function (match) {
      var chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  }

  hiraToKana(str) {
    return str.replace(/[\u3041-\u3096]/g, function (match) {
      var chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  }

}
