import { ComponentLabel } from "../lang/dictionary"
import { getLangFromComponent } from "../lang/logic"
import Num from "./Num"

export default class ConditionRepository {
  static getCondition(devLF: number | undefined, devHF: number | undefined): string {
    const l = devLF;
    const h = devHF;
    if(Num.checkErrorValue(l) || Num.checkErrorValue(h)) return "-"
    else if(l === 0 || h === 0) return "-"
    else if(40 <= l && l <= 60 && 40 <= h && h <= 60) return "通常"
    else if(l >= 50 && h >= 50) {
      // 第1象限
      return l >= 60 && h >= 60 ? "好調" : "やや好調"
    } else if(l <= 50 && h >= 50) {
      // 第2象限
      return l <= 40 && h >= 60 ? "注意力低下" : "やや注力低下"
    } else if(l <= 50 && h <= 50) {
      // 第3象限
      return l <= 40 && h <= 40 ? "不調" : "やや不調"
    } else if(l >= 50 && h <= 50) {
      // 第4象限
      return l >= 60 && h <= 40 ? "高ストレス" : "やや高ストレス"
    } else {
      return "--" // ここには来ない仕様ですが上記の判定が間違っていた場合に表示する値
    }
  }
}