import { Pipe, PipeTransform } from '@angular/core';

import * as jaLocale from 'date-fns'
import * as ja from 'date-fns/locale/ja'


@Pipe({
  name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform {

  transform(date: number): string {
    var string = jaLocale.distanceInWordsToNow(new Date(date), { addSuffix: true, locale: ja });
    string = string.replace("ぐらい", "");
    string = string.replace("以下前", "以内");
    return string;
  }

}
