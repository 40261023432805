import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBoard'
})
export class FilterBoardPipe implements PipeTransform {

  transform(items: any[], value: string): any {

    console.log(items);
    console.log(value);

    return items.filter((x) => {

      if (value == undefined) return true;

      if (x.content) {
        return (x.content.indexOf(value) != -1)
      } else {
        return false;
      }
    });
  }




}
