export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
  }
  
  export interface Transaction {
    item: string;
    cost: number;
  }
  
  export interface Columns {
    def: string;
    name: string;
  }
  
  const COLUMNS_A: Columns[] = [
    { def: "position", name: "位置" },
    { def: "name", name: "名前" },
    { def: "weight", name: "重さ" },
    { def: "symbol", name: "シンボル" },
  ];
  
  const DATA_A: PeriodicElement[] = [
    { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
    { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
    { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
    { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
    { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
    { position: 6, name: "Carbon", weight: 12.0107, symbol: "C" },
    { position: 7, name: "Nitrogen", weight: 14.0067, symbol: "N" },
    { position: 8, name: "Oxygen", weight: 15.9994, symbol: "O" },
    { position: 9, name: "Fluorine", weight: 18.9984, symbol: "F" },
    { position: 10, name: "Neon", weight: 20.1797, symbol: "Ne" },
  ];
  
  const COLUMNS_B: Columns[] = [
    { def: "item", name: "アイテム" },
    { def: "cost", name: "コスト" },
  ];
  
  const DATA_B: Transaction[] = [
    { item: "Beach ball", cost: 4 },
    { item: "Towel", cost: 5 },
    { item: "Frisbee", cost: 2 },
    { item: "Sunscreen", cost: 4 },
    { item: "Cooler", cost: 25 },
    { item: "Swim suit", cost: 15 },
  ];
  
  export const TABLES = {
    a: {
      data: DATA_A,
      columns: COLUMNS_A,
    },
    b: {
      data: DATA_B,
      columns: COLUMNS_B,
    },
  };