import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Password } from 'src/app/class/session/session';
import Validation from 'src/app/class/validations/validation';
import { SessionService } from 'src/app/service/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-three',
  templateUrl: './account-three.component.html',
  styleUrls: ['./account-three.component.css']
})
export class AccountThreeComponent implements OnInit {

  public account = new Password(); // 追加

  constructor(private sessionService: SessionService,
    private router: Router) { } // 追加

  ngOnInit() {
    this.account = this.sessionService.signupInfo;

    if (!this.account.clinic_id) this.router.navigate(['/registration/default']);
    if (environment.debug) {
      console.log(this.account);
    }

  }



}
