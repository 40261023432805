

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ChatMessage } from 'src/app/class/chat-message/chat-message';
import { Patient } from 'src/app/class/patient/patient';
import { Image } from 'src/app/class/image/image';
import { User } from 'src/app/class/user/user';
import { SessionService } from 'src/app/service/session.service';
import { Clinic } from 'src/app/class/clinic/clinic';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { SELECCTIONS_EVENT, SELECCTIONS_PATIENT } from 'src/app/class/selection/selection';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AngularFireStorage } from '@angular/fire/storage';




@Component({
  selector: 'withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.css']
})
export class WithdrawalComponent implements OnInit {



  user: User;

  public target_id: string;
  public targetUser: Observable<User>; // 追加
  public patients: Observable<Patient[]>;

  public clinics: Observable<Clinic[]>;

  constructor(
    private session: SessionService,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private alertViewService: AlertViewService

  ) {

    this.user = this.session.session.user;
    this.session // 追加
      .sessionState
      .subscribe(data => {
        this.user = data.user;
      });

  }

  ngOnInit(): void {


  }



  deleteUser(user) {

    this.alertViewService.showYesNoAlertDeleteWithString(user.name, "さんのの退会手続きを実行します。よろしいですか？").then((ret) => {


      this.db.collection("users").doc(user.uid).update({
        name: "退会済み",
        occupation: "",
        email: "",
        avatar: "",
        tel: "",
        summary: ""
      }).then(() => {

        this.session.logout();
      })

    })


  }



}
