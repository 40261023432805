import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { slideInAnimation } from './animations';
import { Router, RouterOutlet } from '@angular/router';
import { SessionService } from './service/session.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Platform } from '@ionic/angular';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Storage } from '@ionic/storage-angular';
import { AlertViewService } from './services/alert-view.service';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent {
  title = 'angular-bootstrap';

  constructor(private session: SessionService,
    private platform: Platform,
    private badge: Badge,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private firebase: FirebaseX,
    public storage: Storage,
    private router: Router,
    private alertViewService: AlertViewService
  ) {



    this.platformReady();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }




  platformReady() {

    // Call any initial plugins when ready
    this.platform.ready().then(() => {
      this.storage.create();
      if (this.platform.is('cordova')) {
        //Notifications


        this.badge.clear();

        if (this.platform.is('ios')) {
          this.firebase.grantPermission();
        } else {
          console.log("permission dismissed");
        }

        this.firebase.getToken()
          .then(token => {
            this.storage.set('devicetoken', token);
            console.log(`The token is ${token}`)
          }) // save the token server-side and use it to push notifications to this device
          .catch(error => console.error('Error getting token', error));

        this.firebase.onTokenRefresh()
          .subscribe((token: string) => {
            console.log(`Got a new token ${token}`)
            this.storage.set('devicetoken', token);
          });

        this.firebase.onMessageReceived()
          .subscribe((payload) => {
            console.log('Message received. ', payload);

            if (payload.tap != undefined) {
              if (payload.tap === "background") {
                if (payload.isboardroom === "true") {
                  this.router.navigate(['/patient-detail/' + payload.patient_id],);
                } else {
                  this.router.navigate(['/chat/' + payload.patient_id + "/" + payload.target_uid]);
                }
              }
            }

          });
      }
      console.log("splashScreen.show()");
      setTimeout(() => {
        this.splashScreen.hide();
      }, 4000)
      console.log("splashScreen.show()");

    });
  }
}
