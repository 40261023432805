import { Clinic } from "../clinic/clinic";


export class User {

    uid: string;
    name: string;
    avatar: string;
    email: string;
    tel: string;
    clinic_id: string;
    occupation: string;
    summary: string;

    clinic_info: Clinic;



    constructor(
        uid?: string,
        name?: string,
        avatar?: string,
        email?: string,
        tel?: string,
        clinic_id?: string,
        occupation?: string,
        summary?: string,
    ) {
        this.uid = uid ? uid : '';
        this.name = name ? name : '';
        this.avatar = avatar ? avatar : '';
        this.email = email ? email : '';
        this.tel = tel ? tel : '';
        this.clinic_id = clinic_id ? clinic_id : '';
        this.occupation = occupation ? occupation : '';
        this.summary = summary ? summary : '';
    }

    setClinicInfo(info) {
        this.clinic_info = info;
    }

    deserialize() { // 追加
        return Object.assign({}, this);
    }


}
