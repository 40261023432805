import * as firebase from 'firebase/app';

export default class DeviceRepository {
  static getIsLocationVisible(data: any): boolean {
    const isVisible = data !== undefined && (data?.locationSetting === undefined || data.locationSetting > 0);
    return isVisible;
  }

  static sliceMacAddress(macAddress: string): string {
    const ret = macAddress.replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '')
    return ret.slice(-4);
  }

  static async getDeviceSubCate(deviceId: string): Promise<{device: any | null, subcate: any | null}> {
    const device = await firebase.default.firestore().collection("devices").doc(deviceId).get();
    const wearerId = device?.data().wearerId;
    
    return {
      device: device?.data() ?? null,
      subcate: wearerId ? ((await firebase.default.firestore().collection("subcate").doc(wearerId).get())?.data() ?? null) : null
    }
  }

  static getPrioritySubcateIds(): string[] {
    return localStorage.getItem('prioritySubcateIds')?.split(',') ?? [];
  }

  static addPrioritySubcateId(subcateId: string): void {
    const prioritySubcateIds = this.getPrioritySubcateIds();
    prioritySubcateIds.push(subcateId);
    localStorage.setItem('prioritySubcateIds', prioritySubcateIds.join(','));
  }

  static removePrioritySubcateId(subcateId: string): void {
    const prioritySubcateIds = this.getPrioritySubcateIds();
    const newPrioritySubcateIds = prioritySubcateIds.filter((id) => id !== subcateId);
    localStorage.setItem('prioritySubcateIds', newPrioritySubcateIds.join(','));
  }

  static cmp(a, b): number {
    if (a > b) return +1;
    if (a < b) return -1;
    return 0;
}

  static getPriorityDevices(devices: any[]): any[] {
    const prioritySubcateIds = this.getPrioritySubcateIds();
    if (prioritySubcateIds.length === 0) {
      return devices;
    }

    return devices.sort((a, b) => {
      const getIndex = (a: any) => {
        const defaultIndex = 99999;
        const id = a.wearerId;
        if(id === undefined) {
          return defaultIndex;
        }

        const index = prioritySubcateIds.indexOf(id);

        return index < 0 ? defaultIndex : index;
      }

      const getTimestamp = (a: any) => {
        const defaultTimestamp = new Date('2000/1/1');
        return a['timestamp']?.getTime() ?? defaultTimestamp.getTime();
      }

      return this.cmp(getIndex(a),  getIndex(b)) || this.cmp(getTimestamp(b), getTimestamp(a));
    })
  }
}