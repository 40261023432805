import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { getLangFromComponent } from 'src/app/lang/logic';
import DeviceRepository from 'src/app/repository/DeviceRepository';
import { getToday } from 'src/app/repository/Tiime';
declare var google;



@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.map)


  // Map関係
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  map: any;
  marker = null;

  macad = "";
  public device: Observable<any[]>;

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private router: Router

  ) {

    this.macad = (this.route.snapshot.paramMap.get('id'));



  }

  ngOnInit() {
    console.log('ngOnInitです');



    this.device = this.db
      .collection<any>(this.macad, ref => { return ref.where('timestamp', '>=', getToday()).orderBy('timestamp', 'desc').limit(1); })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(action => {
          var data = action.payload.doc.data();

          const shoudNotVisible = DeviceRepository.getIsLocationVisible(data) === false;

          data["time"] = data.timestamp.toDate();

          if (shoudNotVisible) {
            data.location = {
              latitude: 0,
              longitude: 0
            }
          }

          this.loadMap(data).then((ret) => {
            if (this.marker != undefined) {
              this.marker.setMap(null);
            }

            this.marker = new google.maps.Marker({
              position: { lat: data.location.latitude, lng: data.location.longitude },
              map: this.map,
              icon: {
                scaledSize: new google.maps.Size(30, 30), // scaled size
                origin: new google.maps.Point(0, 0), // origin
                anchor: new google.maps.Point(0, 0) // anchor
              }
            });
          });


          console.log(data);
          return data;
        })));

    this.device.subscribe();

  }

  ionViewDidEnter() {
    // this.loadMap();
  }

  // Initialize a blank map
  loadMap(data) {
    return new Promise((resolve) => {
      let latLng = new google.maps.LatLng(data.location.latitude, data.location.longitude);
      let mapOptions = {
        center: latLng,
        zoom: 10,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
      resolve(true);
    });
  }


}
