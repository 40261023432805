import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Password } from 'src/app/class/session/session';
import { SessionService } from 'src/app/service/session.service';
import { Location } from '@angular/common'
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-two',
  templateUrl: './account-two.component.html',
  styleUrls: ['./account-two.component.css']
})
export class AccountTwoComponent implements OnInit {

  imageSrc: any;
  form: FormGroup;
  submitted = false;

  input_areas = "";

  public account = new Password(); // 追加

  constructor(
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private location: Location,
    private router: Router,
    private imageUploadService: ImageUploadService,
  ) { } // 追加

  ngOnInit() {
    this.account = this.sessionService.signupInfo;

    if (!this.account.clinic_name) this.backToRegistoryPage();

    this.form = this.formBuilder.group(
      {
        fullname: [this.account.name, Validators.required],
        email: [this.account.email, [Validators.required, Validators.email]],
        password: [this.account.password, [Validators.required]],
        phone: ['', [Validators.required]],
        occupation: ['', Validators.required],
      }
    );
  }


  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.form.value['occupation'] === 'フリー入力' && this.input_areas.length == 0) {
      return;
    }

    this.form.value.picture = this.imageSrc ? this.imageSrc.replace("RoomsImages%2F", "RoomsImages%2Fthumb_") : "";
    this.form.value.clinic_id = this.account.clinic_id;
    if (this.form.value['occupation'] === 'フリー入力') {
      this.form.value.occupation = this.input_areas;
    }


    if (environment.debug) {
      console.log(this.form.value);
    }
    var account = new Password(this.form.value); // 追加


    this.sessionService.signup(account);


  }

  back() {

    this.location.back();
  }



  compressFile() {
    this.imageUploadService.compressAndUploadFile().then((url) => {
      this.imageSrc = url;
    })
  }

  backToRegistoryPage() {
    this.router.navigate(['/registration/default']);
  }


}
