import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import { ComponentLabel } from '../lang/dictionary';
import { getLangFromComponent } from '../lang/logic';

const lang = getLangFromComponent(ComponentLabel.alertview)

@Injectable({
  providedIn: 'root'
})
export class AlertViewService {

  constructor() { }





  showErrorAlert(msg) {
    Swal.fire({
      icon: 'error',
      text: msg,
      confirmButtonColor: '#2c7be5',
    })
  }

  showGoodAlert(msg) {
    return new Promise((resolve) => {
      Swal.fire({
        title: 'Success !',
        text: msg,
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#2c7be5'

      })
    })
  }

  showGoodAlertJP(msg) {
    return new Promise((resolve) => {
      Swal.fire({
        title: lang.show('完了'),
        text: msg,
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#2c7be5'

      }).then(() => {
        resolve(true);
      })

    })
  }



  showSummaryInfo(title, summary) {
    return new Promise((resolve) => {
      Swal.fire(
        title + lang.show("統計値"),
        summary,
        'info'
      )
    });
  }







  removeEventAlert(msg, repeat) {
    return new Promise((resolve) => {
      Swal.fire({
        title: msg,
        text: "予定を削除します。よろしいですか？",
        showConfirmButton: repeat != null ? true : false,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '以降の予定を全て削除',
        denyButtonText: `この予定を削除`,
        cancelButtonText: `キャンセル`,
        confirmButtonColor: '#d33',

      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          resolve(false);
          this.showGoodAlert('予定は削除されました');
        } else if (result.isDenied) {
          resolve(true);
          this.showGoodAlert('予定は削除されました');
        }
      })
    })
  }


  dismiss() {
    Swal.close();
  }

  notifyPromise(msg) {
    return new Promise((resolve) => {
      Swal.fire({
        position: 'top',
        text: msg,
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        resolve(true);
      })
    });
  }

  notify(msg) {
    Swal.fire({
      position: 'top',
      text: msg,
      showConfirmButton: false,
      timer: 1500
    })
  }

  showYesNoAlertDelete(name) {
    return new Promise((resolve) => {
      Swal.fire({
        title: lang.show('確認'),
        text: "「" + name + "」を削除します。よろしいですか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.show('はい'),
        cancelButtonText: lang.show('いいえ'),
        confirmButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.showGoodAlert(name + 'の削除を成功しました');

          resolve(true);



        }
      })
    });
  }


  showYesNoAlertDeleteWithString(name, message) {
    return new Promise((resolve) => {
      Swal.fire({
        title: lang.show('確認'),
        text: "「" + name + "」" + message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.show('はい'),
        cancelButtonText: lang.show('いいえ'),
        confirmButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.showGoodAlertJP(lang.show('あんしんミマモルナビのご利用ありがとうございました。今後ともあんしんミマモルナビをよろしくお願い致します。')).then(() => {
            resolve(true);
          })

        }
      })
    });
  }

  showDatePicker() {

    return new Promise((resolve) => {
      Swal.fire({
        title: lang.show('CSVダウンロード'),
        text: lang.show("日付を選択してください"),
        icon: 'warning',
        html: `<div class="input-group mb-3" style="overflow-x: clip;">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1">日付</span>
  </div>
  <input type="date" class="form-control" placeholder="2022/01/01" id="pickedDate">
</div>`,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: lang.show('キャンセル'),
        confirmButtonColor: '#d33',
        preConfirm: () => {
          return [
            document.getElementById('pickedDate')["value"]
          ]
        }
      }).then((result) => {

        console.log(result.value[0]);

        resolve(result.value[0]);



      })
    });
  }

  showDateRangePicker() {

    return new Promise((resolve) => {
      Swal.fire({
        title: lang.show('CSVダウンロード'),
        text: lang.show("日付を選択してください"),
        icon: 'warning',
        html: `<div class="input-group mb-3" style="overflow-x: clip;">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">日付</span>
        </div>
        <input type="date" class="form-control" placeholder="2022/01/01" id="pickedDate">
        ～
        <input type="date" class="form-control" placeholder="2022/01/01" id="pickedDate2">
      </div>`,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: lang.show('キャンセル'),
        confirmButtonColor: '#d33',
        preConfirm: () => {
          return [
            document.getElementById('pickedDate')["value"],
            document.getElementById('pickedDate2')["value"]
          ]
        }
      }).then((result) => {

        console.log(result.value[0]);

        resolve(result.value);



      })
    });
  }



  showYesNoAlertForPatientDelete(name) {
    return new Promise((resolve) => {
      Swal.fire({
        title: lang.show('確認'),
        text: "「" + name + "」を非表示にします。よろしいですか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.show('はい'),
        cancelButtonText: lang.show('いいえ'),
        confirmButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.showGoodAlert(name + 'を非表示にしました');

          resolve(true);



        }
      })
    });
  }


  showYesNoAlertAdd(name) {
    return new Promise((resolve) => {
      Swal.fire({
        title: '確認',
        text: "「" + name + "」を追加します。よろしいですか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.show('はい'),
        cancelButtonText: lang.show('いいえ'),
        confirmButtonColor: '#2c7be5'
      }).then((result) => {
        if (result.isConfirmed) {

          this.showGoodAlert(name + 'の追加を成功しました');
          resolve(true);



        }
      })
    });
  }


  showYesNoAlert(name) {
    return new Promise((resolve) => {
      Swal.fire({
        title: '確認',
        text: name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.show('はい'),
        cancelButtonText: lang.show('いいえ'),
        confirmButtonColor: '#2c7be5'
      }).then((result) => {
        if (result.isConfirmed) {

          this.showGoodAlert(name + 'の追加を成功しました');
          resolve(true);



        }
      })
    });
  }

  showYesNoAlertComfirmed(name, resultMSG) {
    return new Promise((resolve) => {
      Swal.fire({
        title: '確認',
        text: name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.show('はい'),
        cancelButtonText: lang.show('いいえ'),
        confirmButtonColor: '#2c7be5'
      }).then((result) => {
        if (result.isConfirmed) {

          this.showGoodAlert(resultMSG);
          resolve(true);

        }
      })
    });
  }


  showYesNoInviteAlertAdd(name) {
    return new Promise((resolve) => {
      Swal.fire({
        title: lang.show('確認'),
        text: name + "に招待メールを送信します。よろしいですか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.show('はい'),
        cancelButtonText: lang.show('いいえ'),
        confirmButtonColor: '#2c7be5'

      }).then((result) => {
        if (result.isConfirmed) {
          this.showGoodAlert(lang.show('招待メールを送信しました'));

          resolve(true);



        }
      })
    });
  }


  showYesNoInviteReadAll() {
    return new Promise((resolve) => {
      Swal.fire({
        title: '確認',
        text: "全てのメッセージを既読状態にします。よろしいですか？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        confirmButtonColor: '#2c7be5'

      }).then((result) => {
        if (result.isConfirmed) {


          resolve(true);



        }
      })
    });
  }

  showLoading() {

    Swal.fire({
      title: lang.show('読み込み中...'),
      backdrop: false,
      // timer: 2000,
      // timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()

      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log('I was closed by the timer')
      }
    })
  }



}
