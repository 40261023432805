import { environment } from "src/environments/environment"

const url = `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/sendNotification`

export class NotificationRepository {
  static async SendNotification(deviceId: string, message: string) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deviceId,
        message,
      }),
    })

    if (!response.ok) {
      throw new Error('Failed to send notification')
    }
  }
}