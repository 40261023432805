import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Password } from 'src/app/class/session/session';
import Validation from 'src/app/class/validations/validation';
import { SessionService } from 'src/app/service/session.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {


  form: FormGroup;
  submitted = false;

  public account = new Password(); // 追加

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location,
    private sessionService: SessionService) { } // 追加

  ngOnInit() {

    document.getElementById("emailelement")["readOnly"] = true;

    this.account = this.sessionService.signupInfo;

    if (!this.account.clinic_name) this.backToRegistoryPage();

    this.form = this.formBuilder.group(
      {
        clinic_name: [this.account.clinic_name, Validators.required],
        fullname: [this.account.name, Validators.required],
        email: [this.account.email, [Validators.required, Validators.email]],
        password: [
          this.account.password,
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ],
        confirmPassword: [this.account.passwordConfirmation, Validators.required],
        acceptTerms: [true, Validators.requiredTrue]
      },
      {
        validators: [Validation.match('password', 'confirmPassword')]
      }
    );
  }

  back() {

    this.location.back();
  }


  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.form.value.clinic_id = this.account.clinic_id;


    var account = new Password(this.form.value); // 追加
    account.setClinicName(this.form.value.clinic_name);
    this.sessionService.passSignUpValues(account);
    this.router.navigate(['/account-two']);

  }

  backToRegistoryPage() {
    this.router.navigate(['/registration/default']);
  }

}
