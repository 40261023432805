


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertViewService } from 'src/app/services/alert-view.service';
import * as moment from 'moment';
import { Storage } from '@ionic/storage-angular';
import { DataZoomComponentOption, EChartsOption } from 'echarts';

import * as firebase from 'firebase/app';
import { Userinfo } from 'src/app/services/userinfo';
import { AngularFirestore } from '@angular/fire/firestore';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { get } from 'http';

const l = getLangFromComponent(ComponentLabel.stressdetailchart);

const addMinutes = (date: Date, minutes: number): Date => new Date(date.getTime() + minutes * 60 * 1000);
const addDays = (date: Date, days: number): Date => new Date(date.getTime() + days * 24 * 60 * 60 * 1000);

const getDayLabels = (): string[] => [...Array(7)].map((_, i) => addDays(new Date(), i - 6)).map(date => moment(date).format('MM/DD/YYYY'));

const getTimesPerMinuites = (): Date[] => {
  const today = new Date();
  const startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
  const dayMinutes = [...Array(24 * 60 * 7)].map((_, i) => i);

  return dayMinutes.map((minutes) => addMinutes(startTime, minutes));
};

const formatTime = (date: Date): string => moment(date).format('H');

const chartTitles: string[] = l.showArray([
  '心拍数',
  '疲労',
  '眠気',
  'ストレス',
  '歩数',
  '歩数（累計）',
  '手動作業量'
]);

const chartColors: string[] = [
  '#0080DD',
  '#CC0000',
  '#00AA00',
  '#AA00AA',
  '#AAAA00',
  '#00CC88',
  '#CC00AA',
];

const units: string[] = l.showArray([
  'bpm',
  'TP',
  '',
  'LFHF',
  '歩',
  '歩',
  '',
]);

@Component({
  selector: 'app-stress-detail-chart',
  templateUrl: './stress-detail-chart.component.html',
  styleUrls: ['./stress-detail-chart.component.css']
})

export class StressDetailChartComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.stressdetailchart)
  minutes: Date[] = getTimesPerMinuites();

  dayLabels: string[] = getDayLabels();
  chartOptionB: EChartsOption[] = [...Array(7)].map(i => this.getInitiChart());
  chartDescriptions: string[] = this.lang.showArray([
    '平均(PR)',
    '疲労(TP)',
    '平均(nemukedo)',
    '平均(LFHF)',
    '平均(1分間歩数)',
    '最大値(歩数)',
    '平均\r\n(3軸加速度合成)',
  ]);

  macAddress: string = '';

  deviceDatas: any[] = [];

  constructor(
    private alertViewService: AlertViewService,
    private localStorage: Storage,
    private userInfo: Userinfo,
    private route: ActivatedRoute,
    private db: AngularFirestore,
  ) {
    this.macAddress = (this.route.snapshot.paramMap.get('id'));
  }

  async setEChart(): Promise<void> {
    this.alertViewService.showLoading();
    
    const datas = await this.getAllChartData();
    console.log({datas: datas.map(array => array.filter(n => n !== NaN).reduce((crr, prev) => crr < prev ? prev : crr, 0))});
    this.chartOptionB = chartTitles.map((title, i) => this.getEchart(title, this.chartDescriptions[i], chartColors[i], datas[i], units[i], i === chartTitles.length - 1));

    this.alertViewService.dismiss();
  }

  async getAllChartData(): Promise<number[][]> {
    await this.setDeviceData();

    return await Promise.all([
      this.getChartDataByField('PR'),
      this.getChartDataByField('TP'),
      this.getChartDataByField('nemukedo'),
      this.getChartDataByField('LFHF'),
      this.getChartDataStepAvg(),
      this.getChartDataByField('steps'),
      this.getChartDataAccAvg(),
    ]);
  }

  async setDeviceData(): Promise<void> {  
    const startTime = this.minutes[0];
    const endTime = addMinutes(this.minutes[this.minutes.length - 1], 1);

    this.deviceDatas = (
      await firebase.default.firestore().collection(this.macAddress)
        .where('endTimestamp', '>=', startTime).where('endTimestamp', '<', endTime).get()
    ).docs.map(doc => doc.data());
  };

  async getChartDataStepAvg(): Promise<number[]> {
    const steps = (await this.getChartData(doc => doc?.steps ?? NaN as number))
      .map(n => n as number);
    const dSteps = steps.map((n, i) => i === 0 ? n : n - steps[i - 1]).map(n => n < 0 ? 0 : n);
    return dSteps;
  }

  async getChartDataAccAvg(): Promise<number[]> {
    return await this.getChartData(doc => {
      const AccXAvg = doc?.AccXAvg;
      const AccYAvg = doc?.AccYAvg;
      const AccZAvg = doc?.AccZAvg;

      return (!AccXAvg || !AccYAvg || !AccZAvg || AccXAvg === NaN || AccYAvg === NaN || AccZAvg === NaN) ? NaN : (Math.abs(AccXAvg) + Math.abs(AccYAvg) + Math.abs(AccZAvg)) / 3;
    });
  }

  async getChartDataByField(fieldName: string): Promise<number[]> {
    return await this.getChartData(doc => doc ? (doc[fieldName] ?? NaN) as number : NaN);
  }

  async getChartData(getValue: (doc: any) => number): Promise<number[]> {
    const docs = this.deviceDatas;

    const dataArray = this.minutes.map(periodStart => {
      const doc = docs.find(document => {
        const time: Date = document.endTimestamp?.toDate();
        if(!time) { return false; }
        const start = periodStart;
        const end = addMinutes(periodStart, 1);

        return time >= start && time < end;
      });

      return getValue(doc);
    });

    return dataArray;
  }

  getInitiChart(): EChartsOption {
    return this.getEchart('', '', '#FF0000', [], '', false);
  }

  getEchart(title: string, description: string, color: string, data: number[], unit: string, isLast: boolean): EChartsOption {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none'
        },
        formatter: function(params) {
          return `${params[0].seriesName} : ${isNaN(params[0].value) ? '-' : params[0].value.toLocaleString()}`;
        },
      },
      grid: {
        top: '8%',
        left: '100px',
        right: '10px',
        bottom: '20%'
      },
      xAxis: {
        type: 'category',
        data: this.minutes.map(formatTime),
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        min: 'dataMin',
        max: 'dataMax',
        axisLabel: {
          show: isLast,
        }
      },
      yAxis: [
        {
          type : 'value',
          axisLabel : {
            formatter: '{value} ' + unit
          },
          name: description,
          nameLocation: 'middle',
          nameGap: 70
        }
      ],
      dataZoom: [],
      series: [
        {
          name: title,
          type: 'line',
          data,
          smooth: true,
          lineStyle: {
            opacity: 0.3,
            color,
          }
        },
      ]
    };
  }

  ngOnInit() {
    this.setEChart();
  }
}
