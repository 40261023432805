import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionService } from 'src/app/service/session.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forgot-pw',
  templateUrl: './forgot-pw.component.html',
  styleUrls: ['./forgot-pw.component.css']
})
export class ForgotPwComponent implements OnInit {

  submitted = false;
  form: FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private session: SessionService,

  ) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        emailFG: ['', [Validators.required, Validators.email]],
      }
    );
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }



  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.session.resetPwd(this.form.value.emailFG).then((ret) => {
      if (ret) {
        this.back();
      }
    })

  }

  back() {
    this.location.back();
  }


}
