import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/class/user/user';
import { SessionService } from 'src/app/service/session.service';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Selection } from 'src/app/class/selection/selection';
import { Patient } from 'src/app/class/patient/patient';
import { AlertViewService } from 'src/app/services/alert-view.service';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-treatment',
  templateUrl: './edit-treatment.component.html',
  styleUrls: ['./edit-treatment.component.css']
})
export class EditTreatmentComponent implements OnInit {


  user: User;

  public clinic_id: string;
  public patients: Observable<Patient[]>;

  public selections: Observable<Selection[]>;

  public selectionPatientListDefault: Observable<any>;
  public selectionPatientListClinic: Observable<any>;

  public selectionEventListDefault: Observable<any>;
  public selectionEventListClinic: Observable<any>;


  constructor(
    private session: SessionService,
    private location: Location,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private router: Router,
    private alertService: AlertViewService,


  ) {
    this.clinic_id = (this.route.snapshot.paramMap.get('id'));

    this.selectionPatientListDefault = db.doc<any>('default-selections/patient').valueChanges();
    this.selectionPatientListClinic = db.doc<any>('clinics/' + this.clinic_id + '/selections/patient').valueChanges();

    this.selectionEventListDefault = db.doc<any>('default-selections/event').valueChanges();
    this.selectionEventListClinic = db.doc<any>('clinics/' + this.clinic_id + '/selections/event').valueChanges();

    this.user = this.session.session.user;
    this.session // 追加
      .sessionState
      .subscribe(data => {
        this.user = data.user;

      });

  }

  asIsOrder(a, b) {
    return a.key.localeCompare(b.key)
  }

  deleteItem(value, fieldname, index, path) {
    this.alertService.showYesNoAlertDelete(value).then((ret) => {
      if (ret) {

        var obj = {};
        obj[`${fieldname}`] = firebase.default.firestore.FieldValue.arrayRemove(value);

        this.db.doc<any>('clinics/' + this.clinic_id + '/selections/' + path).update(obj).then(ret => {
          console.log(ret);
        }).catch(ret => {
          console.log(ret);
        })



      }
    })
  }

  addItem(fieldname: string, index, value, path) {

    if (value) {
      this.alertService.showYesNoAlertAdd(value).then((ret) => {
        if (ret) {
          var obj = {};

          obj[`${fieldname}`] = firebase.default.firestore.FieldValue.arrayUnion(value);

          this.db.doc<any>('clinics/' + this.clinic_id + '/selections/' + path).set(obj, { merge: true }).then(ret => {
            console.log(ret);
          }).catch(ret => {
            console.log(ret);
          })

        }
      })
    }



  }


  showEditDataValue(name, before, path) {
    Swal.fire({
      title: '変更内容を入力',
      titleText: '変更前：' + before,
      input: 'text',
      inputValue: before,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: '登録',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#28A745',
      cancelButtonText: "キャンセル",

      inputValidator: (after) => {
        return new Promise((resolve) => {
          if (after.length < 1) {
            resolve('正しい内容を入力してください');
          } else {


            var objDelete = {};
            objDelete[`${name}`] = firebase.default.firestore.FieldValue.arrayRemove(before);

            this.db.doc<any>('clinics/' + this.clinic_id + '/selections/' + path).update(objDelete).then(ret => {
              console.log(ret);
            }).catch(ret => {
              console.log(ret);
            })

            var obj = {};
            obj[`${name}`] = firebase.default.firestore.FieldValue.arrayUnion(after);

            this.db.doc<any>('clinics/' + this.clinic_id + '/selections/' + path).set(obj, { merge: true }).then(ret => {
              console.log(ret);
            }).catch(ret => {
              console.log(ret);
            })

            resolve(null);
          }
        })
      }
    }).then((result) => {
      console.log(result);
    })
  }

  ngOnInit(): void {
    console.log(this.user);
  }

  back() {
    this.location.back();
  }


}
