

export class Event {


    id: string;
    title: string;
    start: string;
    end: string;
    repeat_id: string;
    date: number;


    constructor(
        id: string,
        title: string,
        start: string,
        end: string,
        repeat_id: string,
        date: number,
    ) {
        this.id = id;
        this.title = title;

        this.start = start ? start : "";
        this.end = end ? end : "";
        this.repeat_id = repeat_id ? repeat_id : "";
        this.date = date ? date : null;

    }

    deserialize() {
        return Object.assign({}, this);
    }
}

export const OPTIONAL_EVENT_INPUTS_A = {
    処置内容: [] as string[],
    持ち込み物品: [] as Object[],
    実施物品: [] as Object[],
}

export const OPTIONAL_EVENT_INPUTS_B = {
    処置内容: "",
    持ち込み物品: "",
    実施物品: "",
}

export const OPTIONAL_EVENT_INPUTS_C = {
    処置内容: "",
    持ち込み物品: "",
    実施物品: "",
    訪問者の職種: "",
}

