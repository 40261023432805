import { ComponentLabel } from "src/app/lang/dictionary";
import { getLangFromComponent } from "src/app/lang/logic";

// 表示タイトルではなく内部タイトル名のため翻訳しない
const defaultTitles = ['熱中症リスク', '心拍モニタリング', '眠気モニタリング', '疲労モニタリング', '気象モニタリング', '自律神経モニタリング', '運転モニタリング', '感情モニタリング', '心拍変動モニタリング'];

const titleStorageKey = 'chart_titles_b';

export interface TitleState {
  titles: string[];
  hideTitles: string[];
}

const initialState: TitleState = {
  titles: defaultTitles,
  hideTitles: []
};

const loadTitles = (): TitleState => {
  const loadedState = localStorage.getItem(titleStorageKey);
  if (!loadedState) {
    saveTitles(initialState);
    return initialState;
  } else {
    return JSON.parse(loadedState);
  }
};

const saveTitles = (state: TitleState) => {
  localStorage.setItem(titleStorageKey, JSON.stringify(state));
};

export const getTitles = (): string[] => {
  const state = loadTitles();

  return state.titles;
};

export const moveUpTitle = (title: string): string[] => {
  const state = loadTitles();
  const index = state.titles.indexOf(title);
  if (index > 0) {
    const temp = state.titles[index - 1];
    state.titles[index - 1] = title;
    state.titles[index] = temp;
    saveTitles(state);
  }

  return getTitles();
};

export const moveDownTitle = (title: string): string[] => {
  const state = loadTitles();
  const index = state.titles.indexOf(title);
  if (index < state.titles.length - 1) {
    const temp = state.titles[index + 1];
    state.titles[index + 1] = title;
    state.titles[index] = temp;
    saveTitles(state);
  }

  return getTitles();
};

export const hideTitle = (title: string): string[] => {
  const state = loadTitles();
  state.hideTitles.push(title);
  state.titles = state.titles.filter(t => t !== title);
  saveTitles(state);

  return getTitles();
};

export const visibleTitle = (title: string): string[] => {
  const state = loadTitles();
  state.titles = [...state.titles.filter(x => x !== title), title];
  state.hideTitles = state.hideTitles.filter(t => t !== title);
  saveTitles(state);

  return getTitles();
};

export const resetTitles = (): string[] => {
  localStorage.removeItem(titleStorageKey);
  return getTitles();
};

export const getHideTitles = (): string[] => {
  const state = loadTitles();
  return state.hideTitles;
};

