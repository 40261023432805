import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ChatMessage } from "../chat-message/chat-message";
import { Clinic } from "../clinic/clinic";
import { Event } from "../event/event";
import { User } from "../user/user";




export class Patient {
    patient_id: string;
    name: string;
    avatar: string;
    disease_name: string; //主病名
    clinic_id: string;    //クリニックID
    members: string[];
    unReadMembers: string[];
    isboardroom: boolean;
    birthday: string;
    latestDate: number;
    updated: number;

    rawData: any;


    public comments: Observable<ChatMessage[]>;
    public events: Observable<Event[]>;
    public member: Observable<User>;
    public clinicRef: Observable<Clinic>;
    public membersRef: Observable<User>[] = [];



    constructor(
        patient_id: string,
        name: string,
        avatar: string,
        members: string[],
        clinic_id: string,
        isboardroom: boolean,
        disease_name: string,
        birthday: string,
        unReadMembers: string[]
    ) {
        this.patient_id = patient_id;
        this.name = name;
        this.avatar = avatar;
        this.members = members;
        this.clinic_id = clinic_id;
        this.isboardroom = isboardroom;
        this.disease_name = disease_name;
        this.birthday = birthday;
        this.unReadMembers = unReadMembers;

    }


    setCommentRef(ref: AngularFirestoreCollection<ChatMessage>) {
        this.comments = ref.snapshotChanges()
            .pipe(
                map(actions => actions.map(action => {
                    // 日付をセットしたコメントを返す
                    const data = action.payload.doc.data() as ChatMessage;
                    const chatMessage = new ChatMessage(data.sender, data.content, data.read, data.image, data.time);
                    if (environment.debug) {
                        console.log(chatMessage);
                    }
                    return chatMessage;
                })));
    }

    setLatestEventRef(ref: AngularFirestoreCollection<Event>) {
        this.events = ref.snapshotChanges()
            .pipe(
                map(actions => actions.map(action => {
                    // 日付をセットしたコメントを返す
                    const data = action.payload.doc.data();
                    if (action.payload.doc.exists) {
                        if (environment.debug) {
                            console.log(data);
                        }
                        return data;
                    } else {
                        return null;
                    }
                })));

    }

    setUpdated(value) {
        this.updated = value;
    }

    setRawData(value) {
        this.rawData = value;
    }

    setMemberRef(ref: AngularFirestoreDocument<User>) {
        this.member = ref.valueChanges();
    }

    setClinicRef(ref: AngularFirestoreDocument<Clinic>) {
        this.clinicRef = ref.valueChanges();
    }


    convertMembersToRef(ref: AngularFirestoreDocument<User>) {


        this.membersRef.push(ref.valueChanges());
    }

    deserialize() { // 追加
        return Object.assign({}, this);
    }

}

export let optionalPatientInputs = {
    郵便番号: "",
    都道府県: "",
    市区町村: "",
    町名: "",
    その他: "",
    介護度: [],
    利き腕: [],
    フリガナ: [],
    身長: [],

    主治医: [],
    往診開始理由: [],
    かかりつけ病院: [],
    その他病名: [],
    現病歴: "",
    既往歴: [],
    家族歴: [],
    "アレルギー・禁忌": [],
    感染症: [],
    家族情報: "",
    連絡先: [],
    訪問看護ステーション: [],
    訪問リハビリ: [],
    ケアマネージャ: "",
    医療保険情報: [],
    介護保険情報: [],
    保険証画像: [],
    管理加算: [],
    定期処置: [],
    デイサービス: [],
    その他要介護状況: [],
    かかりつけ薬局: {
        薬局名: "",
        TEL: "",
        FAX: "",
    },
    訪問薬剤: "",
    性別: "",
    薬剤調達方法: [],
    入浴: [],
    食事: [],
    内服: [],
    排泄: [],
    移動: [],
    麻痺: {
        選択: "",
        内容: "",
    },
    意思疎通: {
        選択: "",
        内容: "",
    },
    認知機能: {
        選択: "",
        内容: "",
    },
    難聴: {
        選択: "",
        内容: "",
    },
    視覚障害: {
        選択: "",
        内容: "",
    },
    頓用薬: [],
    ワクチン接種: {
        名称: "",
        接種日: "",
    },
    注意事項: "",
    アクセス情報: [],
    アクセス情報補足: "",
};



export const OPTIONAL_PARENT_INPUTS_A = {
    介護度: [] as string[],
    利き腕: [] as string[],
    フリガナ: [] as string[],
    主治医: [] as string[],
    往診開始理由: [] as string[],
    かかりつけ病院: [] as string[],
    その他病名: [] as string[],
    既往歴: [] as string[],
    家族歴: [] as string[],
    "アレルギー・禁忌": [] as string[],
    感染症: [] as string[],
    家族情報: [] as string[],
    連絡先: [] as string[],
    訪問看護ステーション: [] as string[],
    訪問リハビリ: [] as string[],
    医療保険情報: [] as string[],
    介護保険情報: [] as string[],
    保険証画像: [] as string[],
    管理加算: [] as string[],
    定期処置: [] as string[],
    デイサービス: [] as string[],
    その他要介護状況: [] as string[],
    かかりつけ薬局: [] as string[],
    訪問薬剤: [] as string[],
    性別: [] as string[],
    薬剤調達方法: [] as string[],
    入浴: [] as string[],
    食事: [] as string[],
    内服: [] as string[],
    排泄: [] as string[],
    移動: [] as string[],
    拘縮: [] as string[],
    認知機能低下: [] as string[],
    難聴: [] as string[],
    視覚障害: [] as string[],
    頓用薬: [] as string[],
    ワクチン接種: [] as string[],
    アクセス情報: [] as string[],

}
export const OPTIONAL_PARENT_INPUTS_B = {
    介護度: "",
    利き腕: "",
    フリガナ: "",
    身長: "",
    主治医: "",
    往診開始理由: "",
    かかりつけ病院: "",
    その他病名: "",
    既往歴: "",
    家族歴: "",
    "アレルギー・禁忌": "",
    感染症: "",
    家族情報: "",
    連絡先: "",
    訪問看護ステーション: "",
    訪問リハビリ: "",
    ケアマネージャ: "",
    医療保険情報: "",
    介護保険情報: "",
    保険証画像: "",
    管理加算: "",
    定期処置: "",
    デイサービス: "",
    その他要介護状況: "",
    かかりつけ薬局: "",
    訪問薬剤: "",
    性別: "",
    薬剤調達方法: "",
    入浴: "",
    食事: "",
    内服: "",
    排泄: "",
    移動: "",
    拘縮: "",
    認知機能低下: "",
    難聴: "",
    視覚障害: "",
    頓用薬: "",
    ワクチン接種: "",
    アクセス情報: "",

}

export const OPTIONAL_PARENT_INPUTS_C = {
    介護度: "",
    利き腕: "",
    フリガナ: "",
    身長: "",
    主治医: "",
    往診開始理由: "",
    かかりつけ病院: "",
    その他病名: "",
    既往歴: "",
    家族歴: "",
    "アレルギー・禁忌": "",
    感染症: "",
    家族情報: "",
    連絡先: { name: "", num: "" },
    訪問看護ステーション: "",
    訪問リハビリ: "",
    医療保険情報: "",
    介護保険情報: "",
    保険証画像: "",
    管理加算: "",
    定期処置: "",
    デイサービス: "",
    その他要介護状況: "",
    かかりつけ薬局: { name: "", num: "", fax: "" },
    訪問薬剤: "",
    性別: "",
    薬剤調達方法: "",
    入浴: "",
    食事: "",
    内服: "",
    排泄: "",
    移動: "",
    拘縮: "",
    認知機能低下: "",
    難聴: "",
    視覚障害: "",
    頓用薬: "",
    ワクチン接種: { name: "", num: "" },
    アクセス情報: "",
}
