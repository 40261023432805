


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertViewService } from 'src/app/services/alert-view.service';
import * as moment from 'moment';
import { Storage } from '@ionic/storage-angular';
import { EChartsOption } from 'echarts';

import * as firebase from 'firebase/app';
import { Userinfo } from 'src/app/services/userinfo';
import { AngularFirestore } from '@angular/fire/firestore';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';

const addMinutes = (date: Date, minutes: number): Date => new Date(date.getTime() + minutes * 60 * 1000);

const getTimesPerMinuites = (): Date[] => {
  const today = new Date();
  const startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const dayMinutes = [...Array(24 * 60)].map((_, i) => i);

  return dayMinutes.map((minutes) => addMinutes(startTime, minutes));
};

const formatTime = (date: Date): string => moment(date).format('HH:mm');

@Component({
  selector: 'app-nettyusho-risk-chart',
  templateUrl: './nettyusho-risk-chart.component.html',
  styleUrls: ['./nettyusho-risk-chart.component.css']
})

export class NettyushoRiskChartComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.nettyushoriskchart)

  minutes: Date[] = getTimesPerMinuites();

  chartOptionB: EChartsOption = {
    title: {
      text: this.lang.show('熱中症リスク度の推移'),
      left: 0
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },
    legend: {
      data: [this.lang.show('熱中症リスク度'), '75%']
    },
    grid: {
      left: '10%',
      right: '10%',
      bottom: '15%'
    },
    xAxis: {
      type: 'category',
      data: this.minutes.map(formatTime),
      boundaryGap: false,
      axisLine: { onZero: false },
      splitLine: { show: false },
      min: 'dataMin',
      max: 'dataMax'
    },
    yAxis: {
      min: 0,
      max: 100,
      scale: true,
      splitArea: {
        show: true
      }
    },
    dataZoom: [
      {
        type: 'inside',
        start: 50,
        end: 100
      },
      {
        show: true,
        type: 'slider',
        top: '90%',
        start: 50,
        end: 100
      }
    ],
    series: [
      {
        name: this.lang.show('熱中症リスク度'),
        type: 'line',
        data: [],
        smooth: true,
        lineStyle: {
          opacity: 0.3
        }
      },
      {
        name: '75%',
        type: 'line',
        data: this.minutes.map(() => 75),
        smooth: true,
        lineStyle: {
          opacity: 0.8
        },
        itemStyle: {
          color: '#e63757'
        }
      }
    ]
  };

  macAddress: string = '';
  lastTime: Date | null = null;

  constructor(
    private alertViewService: AlertViewService,
    private localStorage: Storage,
    private userInfo: Userinfo,
    private route: ActivatedRoute,
    private db: AngularFirestore,
  ) {
    this.macAddress = (this.route.snapshot.paramMap.get('id'));
  }

  async setEChart(): Promise<void> {
    this.chartOptionB.series[0].data = await this.getRiskData();
    this.chartOptionB = { ...this.chartOptionB };
  }

  async getRiskData(): Promise<number[]> {
    const startTime = this.minutes[0];
    const endTime = addMinutes(this.minutes[this.minutes.length - 1], 1);

    console.log({startTime, endTime});

    const docs = (
      await firebase.default.firestore().collection(this.macAddress)
        .where('endTimestamp', '>=', startTime).where('endTimestamp', '<', endTime).get()
    ).docs.map(doc => doc.data());

    const dataArray = this.minutes.map(periodStart => {
      const doc = docs.find(document => {
        const time: Date = document.endTimestamp?.toDate();
        if(!time) { return false; }
        const start = periodStart;
        const end = addMinutes(periodStart, 1);

        return time >= start && time < end;
      });

      return (doc?.atsusaRisukudo ?? 0) as number;
    });

    this.lastTime = docs.map(doc => doc.endTimestamp).filter(x => x).sort((a, b) => -a.toDate().getTime() + b.toDate().getTime())[0]?.toDate() ?? null;


    return dataArray;
  }

  ngOnInit() {
    this.setEChart();
  }
}
