export class Clinic {

    id: string;
    name: string;
    event_color: string;
    createdByVisitorList: boolean;

    constructor(
        name: string,
        event_color: string
    ) {
        this.name = name;
        this.event_color = event_color;
    }

    setId(id) {
        this.id = id;
    }

    setCreatedByVisitorList(bool) {
        this.createdByVisitorList = bool;
    }

    deserialize() { // 追加
        return Object.assign({}, this);
    }
}


export var CLINIC_COLORS = [
    "#D50000",
    "#F4511E",
    "#33B679",
    "#039BE5",
    "#7986CB",
    "#616161",
    "#E67C73",
    "#F6BF26",
    "#0B8043",
    "#3F51B5",
    "#8E24AA",
    "#7F7F7F",
    "#3F48CC",
    "#A349A4",
    "#B97A57",
    "#7092BE",
    "#C8BFE7"
]