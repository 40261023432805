import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Subject } from 'rxjs';
import { Event } from '../class/event/event';
import { Patient } from '../class/patient/patient';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {


  constructor(private _ref: ChangeDetectorRef) { }


  transform(patients: any[], field: string, events: Event[],) {

    // if (!events || !patients) return patients;

    console.log("orderby-called...");
    console.log(patients);

    return patients.sort((a, b) => new Date(a.time).getTime() >= new Date(b.time).getTime() ? -1 : 1).concat()

  }

}
