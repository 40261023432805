

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ChatMessage } from 'src/app/class/chat-message/chat-message';
import { Patient } from 'src/app/class/patient/patient';
import { Image } from 'src/app/class/image/image';
import { User } from 'src/app/class/user/user';
import { SessionService } from 'src/app/service/session.service';
import { Clinic } from 'src/app/class/clinic/clinic';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { SELECCTIONS_EVENT, SELECCTIONS_PATIENT } from 'src/app/class/selection/selection';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';

import * as firebase from 'firebase/app';


@Component({
  selector: 'app-talk-list',
  templateUrl: './talk-list.component.html',
  styleUrls: ['./talk-list.component.css']
})
export class TalkListComponent implements OnInit {


  public patients: Observable<Patient[]>;
  public clinics: Observable<Clinic[]>;
  numOfCommentSlice = 40;

  related_members: User[] = [];

  searchText: string;


  public currentUser: User;

  shiborikomiWord = "";
  showOnlyUnread = false;

  constructor(
    private router: Router,
    private db: AngularFirestore,
    private session: SessionService,
    private alertViewService: AlertViewService,
    private localStorage: Storage,
  ) {

    this.putLocalStorageForShowOnlyUnread();

    console.log("read start !!!!!")
    this.session // 追加
      .sessionState
      .subscribe(data => {
        if (environment.debug) {
          console.log(data);
        }
        this.currentUser = data.user;



        this.patients = db
          .collection<Patient>('patients', ref => { return ref.where('isboardroom', '==', false).where('members', 'array-contains', this.currentUser.uid).orderBy('updated', 'desc'); })
          .snapshotChanges()
          .pipe(
            map(actions => actions.map(action => {
              // 日付をセットしたコメントを返す
              const data = action.payload.doc.data() as Patient;
              const patientData = new Patient(
                data.patient_id,
                data.name,
                data.avatar,
                data.members,
                data.clinic_id,
                data.isboardroom,
                data.disease_name,
                data.birthday,
                data.unReadMembers
              );
              if (environment.debug) {
                console.log(patientData);
              }
              patientData.setCommentRef(db.collection('patients').doc(data.patient_id).collection<ChatMessage>('comments', ref => { return ref.orderBy('time', 'desc').limit(1); }));

              patientData["フリガナ"] = data["フリガナ"] ? data["フリガナ"] : "";
              patientData["主治医"] = data["主治医"] ? data["主治医"].concat() : "";

              if (!data.isboardroom) {
                const partner_id = data.members.filter(n => n !== this.currentUser.uid)[0];
                patientData.setMemberRef(db.doc<User>('users/' + partner_id));
              }
              console.log("read end !!!!!")
              return patientData;
            })));

        this.setDefaultSelections();
      });




  }

  ngOnInit(): void {

    const button = document.querySelector(".invitepeople .dropdown-menu");

    button.addEventListener('click', event => {
      event.stopPropagation();
    });
  }

  clickedUnreadButton(state) {
    this.showOnlyUnread = state;
    this.localStorage.set("showOnlyUnreadNewTalk", state);
  }



  putLocalStorageForShowOnlyUnread() {
    this.localStorage.get("showOnlyUnreadNewTalk").then((ret) => {
      this.showOnlyUnread = ret;
    })
  }

  shiborikomi(clinics, member) {

    if (this.shiborikomiWord.length == 0) return true;


    const clinicName = this.getClinicName(clinics, member.clinic_id);


    if ((clinicName + member.name).indexOf(this.shiborikomiWord) != -1) {

      return true;
    } else {
      return false;
    }


  }


  shouldShowUnReadBadge(patient) {
    return patient.unReadMembers.includes(this.currentUser.uid);
  }

  loadInvites() {

    var members_id = [];
    this.related_members = [];

    // 同じ事業所の同僚を検索
    this.db.collection<User>('users', ref => { return ref.where('clinic_id', '==', this.currentUser.clinic_id) })
      .get().toPromise().then((snapshot) => {

        snapshot.forEach(doc => {
          if (environment.debug) {
            console.log(doc.id, '=>', doc.data());
          }
          const data = doc.data() as User;
          const userData = new User(
            data.uid,
            data.name,
            data.avatar,
            data.email,
            data.tel,
            data.clinic_id,
            data.occupation,
            data.summary
          );

          members_id.push(userData.uid);

        });


        // 患者さんに関係する医療従事者を検索する
        this.patients.pipe(first()).subscribe((patients) => {
          if (environment.debug) {
            console.log(patients)
          }
          patients.forEach((patient) => {
            patient.members.forEach((id) => {
              members_id.push(id);
            })
          })

          // member_idの重複削除
          members_id = [...new Set(members_id)];




          // 追加済Userをmember_idから削除
          patients.forEach((patient) => {
            if (!patient.isboardroom) {
              patient.members.forEach((id) => {
                members_id = members_id.filter(member => {
                  return member !== id;
                })
              })
            }
          })

          // 自分を除外
          members_id = members_id.filter(member => {
            return member !== this.currentUser.uid;
          })

          members_id.forEach((id) => {
            this.db.collection<User>('users').doc(id)
              .get().toPromise().then((doc) => {
                const data = doc.data() as User;
                const userData = new User(
                  data.uid,
                  data.name,
                  data.avatar,
                  data.email,
                  data.tel,
                  data.clinic_id,
                  data.occupation,
                  data.summary
                );
                if (this.related_members.filter((item) => userData.uid === item.uid).length) {

                } else {
                  this.related_members.push(userData);
                }

                patients.forEach(patient => {

                })




              });
          })
        });
      })


    this.clinics = this.db
      .collection<Clinic>('clinics', ref => { return ref.where('createdByVisitorList', '==', false) })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(action => {
          // 日付をセットしたコメントを返す
          const data = action.payload.doc.data();
          const clinicData = new Clinic(
            data.name,
            data.event_color
          );
          clinicData.setId(action.payload.doc.id)
          if (environment.debug) {
            console.log(clinicData)
          }
          return clinicData;
        })));
  }



  getClinicName(clinics, id) {
    if (id == undefined) return "";
    if (clinics != null) {
      return clinics.find(item => item.id === id) ? clinics.find(item => item.id === id).name : "";
    };
  }

  receiveSearchText(event) {
    console.log(event);
    this.searchText = event;
  }

  filterByDate(commnets) {

    if (commnets.length > 0) {
      const _d = new Date();
      const today = new Date(_d.getFullYear(), _d.getMonth(), _d.getDate(), 0, 0, 0);

      const time = commnets[0].time;

      if (today.getTime() < time) {
        return true;
      }

      console.log(time);
    }
    return false;
  }

  gotoNextPage(room) {

    if (room.isboardroom) {
      this.router.navigate(['/patient-detail/' + room.patient_id]).then(() => {
      });

    } else {
      room.member.pipe(first()).subscribe((member) => {
        this.router.navigate(['/chat/' + room.patient_id + "/" + member.uid]);
      })

    }
  }

  clearUnreadMessages() {
    const value = "個別トークの未読マーク(赤いマーク)を全てクリアします。よろしいですか？";
    const value2 = "未読マークを全てクリアしました。未読メッセージは0件です。";
    this.alertViewService.showYesNoAlertComfirmed(value, value2).then((ret) => {
      if (ret) {
        this.db.collection("patients", ref => { return ref.where('unReadMembers', 'array-contains', this.currentUser.uid).where('isboardroom', '==', false); }).get().toPromise().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              unReadMembers: firebase.default.firestore.FieldValue.arrayRemove(this.currentUser.uid)
            });
          });
        });
      }
    })

  }


  inviteChat(member: User) {
    const id = this.db.createId();
    if (environment.debug) {
      console.log(member);
    }
    const patient = new Patient(
      id,
      member.name,
      null,
      [this.currentUser.uid, member.uid],
      null,
      false,
      "",
      "",
      []
    );
    patient.setUpdated(+moment());

    this.db.collection("patients").doc(id).set(patient.deserialize()).then(() => {
      this.alertViewService.notify("トークルームを追加しました");

    })



  }



  setDefaultSelections() {
    const usersRef = this.db.collection('default-selections').doc('patient')
    usersRef.get().toPromise().then((docSnapshot) => {
      if (docSnapshot.exists) {

      } else {
        this.db.collection("default-selections").doc("patient").set(SELECCTIONS_PATIENT).then(() => {

        });

        this.db.collection("default-selections").doc("event").set(SELECCTIONS_EVENT).then(() => {

        });
      }
    });


  }

  releaseNumOfList() {
    this.numOfCommentSlice = this.numOfCommentSlice + 50;
  }






}
