import * as firebase from 'firebase/app';

const addMinutes = (d: Date, minutes: number): Date => new Date(d.getTime() + minutes * 60000);
export const meDeviceThen = (deviceId: string, action: () => void) => {
  if(deviceId.indexOf('2084') >= 0) {
    action();
  }
};

export const AtsusaRepository = {
  getAtsusaRiskTodayMax: async (deviceId: string): Promise<number> => {
    const date = new Date();
    const start = new Date(date.setHours(0, 0, 0, 0));
    const end = new Date(date.setHours(23, 59, 59, 0));

    const db = firebase.default.firestore();
    const deviceRef = db.collection(deviceId).where('timestamp', '>', start)
        .where('timestamp', '<', end).orderBy('timestamp', 'desc').limit(10000);

    let atsusaRisukudoDayMax = 0;
    (await deviceRef.get()).docs.forEach(doc => {
      const value = doc.data().atsusaRisukudo;
      if(value !== undefined && value > atsusaRisukudoDayMax) {
        atsusaRisukudoDayMax = value;
      }
    });

    return atsusaRisukudoDayMax;
  }
};


