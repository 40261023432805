import { Component, OnInit } from '@angular/core';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { getLangFromComponent } from 'src/app/lang/logic';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.pagenotfound)

  constructor() { }

  ngOnInit(): void {
  }

}
