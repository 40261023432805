import { Component, OnInit } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { User } from 'src/app/class/user/user';
import { SessionService } from 'src/app/service/session.service';
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { AlertViewService } from 'src/app/services/alert-view.service';


import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Selection } from 'src/app/class/selection/selection';
import { optionalPatientInputs, OPTIONAL_PARENT_INPUTS_A, OPTIONAL_PARENT_INPUTS_B, OPTIONAL_PARENT_INPUTS_C, Patient } from 'src/app/class/patient/patient';
import * as firebase from 'firebase/app';
import { first, map, switchMap, take } from 'rxjs/operators';
import * as moment from 'moment';
import { Clinic } from 'src/app/class/clinic/clinic';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.css']
})
export class AddPatientComponent implements OnInit {
  imageSrc: any;
  public currentUser: User;
  public clinic_id: string;

  form: FormGroup;

  submitted = false;

  defaultSelecctions: Observable<any>;
  clinicSelections: Observable<any>;

  public patient: Observable<Patient>;
  public patient_id: string

  public arrays = { ...OPTIONAL_PARENT_INPUTS_A };
  public selected_areas = { ...OPTIONAL_PARENT_INPUTS_B };
  public input_areas = { ...OPTIONAL_PARENT_INPUTS_C };


  public selections: Observable<Selection[]>;


  constructor(
    private imageUploadService: ImageUploadService,
    private formBuilder: FormBuilder,
    private db: AngularFirestore,
    private route: ActivatedRoute,

    private session: SessionService,
    private alertViewService: AlertViewService,
    private location: Location,) {



    this.clearInputAreas()




    this.clinic_id = (this.route.snapshot.paramMap.get('clinic_id'));
    this.patient_id = (this.route.snapshot.paramMap.get('patient_id'));


    this.currentUser = this.session.session.user;

    this.session // 追加
      .sessionState
      .subscribe(data => {
        if (environment.debug) {
          console.log(data);
        }
        this.currentUser = data.user;
      });

    this.defaultSelecctions = db.doc<any>('default-selections/patient').valueChanges();
    this.clinicSelections = db.doc<any>('clinics/' + this.currentUser.clinic_id + '/selections/patient').valueChanges();


    this.patient = this.db
      .collection('patients')
      .doc(this.patient_id)
      .valueChanges()
      .pipe(

        switchMap((patient: Patient) => {
          if (patient) {
            return of(patient);
          } else {
            return of(null);
          }
        })
      );
  }

  ngAfterViewInit() {
    console.log("ionViewDidEnter");
    setTimeout(() => {
      this.selected_areas["介護度"] = "フリー入力";
      this.selected_areas["主治医"] = "フリー入力";
      this.selected_areas["往診開始理由"] = "フリー入力";
      this.selected_areas["かかりつけ病院"] = "フリー入力";
      this.selected_areas["その他病名"] = "フリー入力";

      this.selected_areas["既往歴"] = "フリー入力";
      this.selected_areas["家族歴"] = "フリー入力";
      this.selected_areas["アレルギー・禁忌"] = "フリー入力";
      this.selected_areas["感染症"] = "フリー入力";

      this.selected_areas["訪問看護ステーション"] = "フリー入力";
      this.selected_areas["訪問リハビリ"] = "フリー入力";

      this.selected_areas["医療保険情報"] = "フリー入力";
      this.selected_areas["介護保険情報"] = "フリー入力";
      this.selected_areas["管理加算"] = "フリー入力";
      this.selected_areas["定期処置"] = "フリー入力";
      this.selected_areas["デイサービス"] = "フリー入力";
      this.selected_areas["その他要介護状況"] = "フリー入力";
      this.selected_areas["薬剤調達方法"] = "フリー入力";

      this.selected_areas["入浴"] = "フリー入力";
      this.selected_areas["食事"] = "フリー入力";
      this.selected_areas["内服"] = "フリー入力";
      this.selected_areas["排泄"] = "フリー入力";
      this.selected_areas["移動"] = "フリー入力";

      this.selected_areas["頓用薬"] = "フリー入力";
    }, 1000)
  }


  clearInputAreas() {

    this.input_areas["かかりつけ薬局"] = { name: '', num: '', fax: '' }
    this.input_areas["ワクチン接種"] = { name: '', num: '' }
    this.input_areas["連絡先"] = { name: '', num: '' }






  }

  autoSetAddress() {
    this.form.controls["郵便番号"].setValue(this.form.value["郵便番号"].replace("-", ""));
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        フリガナ: [''],
        birthday: ['1950-01-01', [Validators.required]],
        disease_name: ['', Validators.required],
        members: [[this.currentUser.uid]],
        郵便番号: [''],
        都道府県: [''],
        市区町村: [''],
        町名: [''],
        その他: [''],
        利き腕: [''],
        身長: [''],
        現病歴: [''],
        家族情報: [''],
        ケアマネージャ: ["フリー入力"],
        ケアマネージャ新規: [''],
        訪問薬剤: [''],
        性別: [''],
        拘縮選択: [''],
        拘縮入力: [''],
        認知機能選択: [''],
        認知機能入力: [''],
        難聴選択: [''],
        難聴入力: [''],
        視覚障害選択: [''],
        視覚障害入力: [''],
        注意事項: [''],
        アクセス情報補足: ['']
      }
    );



    if (this.patient_id !== "new") {
      this.setParentValues();
    }

  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }



  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    if (environment.debug) {
      console.log(JSON.stringify(this.form.value, null, 2));
    }
    this.form.value.avatar = this.imageSrc ? this.imageSrc : "";

    this.form.value.unReadMembers = this.form.value.unReadMembers ? this.form.value.unReadMembers : [];
    this.form.value.isboardroom = true;
    this.form.value.clinic_id = this.currentUser.clinic_id;
    this.form.value.updated = +moment();
    this.form.value.介護度 = this.arrays.介護度.concat();
    this.form.value.主治医 = this.arrays.主治医.concat();
    this.form.value.往診開始理由 = this.arrays.往診開始理由.concat();
    this.form.value.かかりつけ病院 = this.arrays.かかりつけ病院.concat();
    this.form.value.その他病名 = this.arrays.その他病名.concat();
    this.form.value.既往歴 = this.arrays.既往歴.concat();
    this.form.value.家族歴 = this.arrays.家族歴.concat();
    this.form.value["アレルギー・禁忌"] = this.arrays["アレルギー・禁忌"].concat();
    this.form.value.感染症 = this.arrays.感染症.concat();
    this.form.value.連絡先 = this.arrays.連絡先.concat();
    this.form.value.訪問看護ステーション = this.arrays.訪問看護ステーション.concat();
    this.form.value.訪問リハビリ = this.arrays.訪問リハビリ.concat();
    this.form.value.医療保険情報 = this.arrays.医療保険情報.concat();
    this.form.value.介護保険情報 = this.arrays.介護保険情報.concat();
    this.form.value.保険証画像 = this.arrays.保険証画像.concat();
    this.form.value.管理加算 = this.arrays.管理加算.concat();
    this.form.value.定期処置 = this.arrays.定期処置.concat();
    this.form.value.デイサービス = this.arrays.デイサービス.concat();
    this.form.value.その他要介護状況 = this.arrays.その他要介護状況.concat();
    this.form.value.かかりつけ薬局 = this.arrays.かかりつけ薬局.concat();
    this.form.value.薬剤調達方法 = this.arrays.薬剤調達方法.concat();
    this.form.value.入浴 = this.arrays.入浴.concat();
    this.form.value.食事 = this.arrays.食事.concat();
    this.form.value.内服 = this.arrays.内服.concat();
    this.form.value.排泄 = this.arrays.排泄.concat();
    this.form.value.移動 = this.arrays.移動.concat();
    this.form.value.視覚障害 = this.arrays.視覚障害.concat();
    this.form.value.頓用薬 = this.arrays.頓用薬.concat();
    this.form.value.ワクチン接種 = this.arrays.ワクチン接種.concat();
    this.form.value.アクセス情報 = this.arrays.アクセス情報.concat();



    if (this.patient_id !== "new") {
      this.form.value.patient_id = this.patient_id;
      this.db.collection('patients').doc(this.patient_id).set(this.form.value).then(() => {
        this.alertViewService.notify("患者様情報を更新しました");
        this.back();
      })
    } else {
      this.db.collection('patients').add(this.form.value).then((ref: DocumentReference) => {
        this.db.collection('patients').doc(ref.id).update({ patient_id: ref.id });
      }).then(() => {
        this.alertViewService.notify("患者様情報を追加しました");
        this.back();
      })
    }

  }

  back() {
    this.location.back();
  }


  shouldShowFavButton(value, listsA, listB) {
    if (listsA == undefined) {
      listsA = []
    } else if (listB == undefined) {
      listB = []
    }

    if (listsA.includes(value) || listB.includes(value)) {
      return false;
    } else {
      return true;
    }
  }


  addFavoriteItem(fieldname: string, index, value, path) {
    if (value) {
      this.alertViewService.showYesNoAlertAdd(value).then((ret) => {
        if (ret) {
          var obj = {};

          obj[`${fieldname}`] = firebase.default.firestore.FieldValue.arrayUnion(value);

          this.db.doc<any>('clinics/' + this.clinic_id + '/selections/' + path).set(obj, { merge: true }).then(ret => {
            console.log(ret);
          }).catch(ret => {
            console.log(ret);
          })

        }
      })
    }
  }






  compressFile() {
    this.imageUploadService.compressAndUploadFile().then((url) => {
      this.imageSrc = url;
    })
  }



  addItemArray(name) {
    if (this.selected_areas[name] === "フリー入力") {
      if (this.input_areas[name].length) {
        this.arrays[name].push(this.input_areas[name]);
        this.input_areas[name] = "";
      }

    } else {
      if (this.selected_areas[name].length)
        this.arrays[name].push(this.selected_areas[name]);
    }

    //重複削除
    this.arrays[name] = [...new Set(this.arrays[name])];
  }

  addImageArray(name) {
    this.imageUploadService.compressAndUploadFile().then((url) => {
      this.imageSrc = url;
    })
  }

  addRenrakusakiArray(name) {
    if (this.input_areas[name].name) {
      this.arrays[name].push({
        name: this.input_areas[name].name,
        num: this.input_areas[name].num
      });

      this.input_areas[name].name = "";
      this.input_areas[name].num = "";
    }

  }


  addKakaritsukeArray(name) {
    if (this.input_areas[name].name) {
      this.arrays[name].push({
        name: this.input_areas[name].name,
        num: this.input_areas[name].num,
        fax: this.input_areas[name].fax
      });

      this.input_areas[name].name = "";
      this.input_areas[name].num = "";
      this.input_areas[name].fax = "";
    }

  }

  addKanrikasanArray(name, value) {
    this.arrays[name].push(value);
  }

  deleteItemArray(name, value) {
    this.arrays[name] = this.arrays[name].filter(n => n !== value);
  }

  deleteRenrakusakiArray(name, value) {
    this.arrays[name] = this.arrays[name].filter(n => n.name !== value);
  }

  deleteKanrikasanArray(name, value) {
    this.arrays[name] = this.arrays[name].filter(n => n !== value);
  }


  imageSelect(name, event) {
    this.input_areas[name] = event.target.files[0];
  }

  upload(name) {

    this.imageUploadService.compress(this.input_areas[name])
      .pipe(take(1))
      .subscribe(compressedImage => {
        console.log(compressedImage)
        // now you can do upload the compressed image

        this.imageUploadService.uploadToFirestorage(compressedImage).then((url: string) => {
          this.arrays[name].push(url);
        })
      })
  }


  kanriKasanChanged(name, event) {

    if (event.target.checked) {
      this.addKanrikasanArray(name, event.target.id);
    } else {
      this.deleteKanrikasanArray(name, event.target.id);
    }
  }



  setParentValues() {


    this.patient.pipe(first()).subscribe((patient) => {

      // Set Values
      this.form = this.formBuilder.group(
        {
          name: [patient.name, Validators.required],
          フリガナ: [patient["フリガナ"]],
          birthday: [patient.birthday, [Validators.required]],
          disease_name: [patient.disease_name, Validators.required],
          members: [patient.members.concat()],
          unReadMembers: [patient.unReadMembers.concat()],
          isboardroom: [patient.isboardroom],
          郵便番号: [patient["郵便番号"]],
          都道府県: [patient["都道府県"]],
          市区町村: [patient["市区町村"]],
          町名: [patient["町名"]],
          その他: [patient["その他"]],
          利き腕: [patient["利き腕"]],
          身長: [patient["身長"]],
          現病歴: [patient["現病歴"]],
          家族情報: [patient["家族情報"]],
          ケアマネージャ: ["フリー入力"],
          ケアマネージャ新規: [patient["ケアマネージャ新規"]],
          訪問薬剤: [patient["訪問薬剤"]],
          性別: [patient["性別"]],
          拘縮選択: [patient["拘縮選択"]],
          拘縮入力: [patient["拘縮入力"]],
          認知機能選択: [patient["認知機能選択"]],
          認知機能入力: [patient["認知機能入力"]],
          難聴選択: [patient["難聴選択"]],
          難聴入力: [patient["難聴入力"]],
          視覚障害選択: [patient["視覚障害選択"]],
          視覚障害入力: [patient["視覚障害入力"]],
          注意事項: [patient["注意事項"]],
          アクセス情報補足: [patient["アクセス情報補足"]],
        }
      );
      this.imageSrc = patient.avatar;


      Object.keys(this.arrays).forEach(key => {
        if (environment.debug) {
          console.log(key);
        }
        this.arrays[key] = patient[key] ? patient[key].concat() : [];
      });

    })


  }




  showEditDataValue(name, before) {
    Swal.fire({
      title: '変更内容を入力',
      titleText: '変更前：' + before,
      input: 'text',
      inputValue: before,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: '登録',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#28A745',
      cancelButtonText: "キャンセル",

      inputValidator: (after) => {
        return new Promise((resolve) => {
          if (after.length < 1) {
            resolve('正しい内容を入力してください');
          } else {
            this.arrays[name].push(after);
            this.deleteItemArray(name, before);

            //重複削除
            this.arrays[name] = [...new Set(this.arrays[name])];
            resolve(null);
          }
        })
      }
    }).then((result) => {
      if (environment.debug) {
        console.log(result);
      }
    })
  }

  showEditDataKakaritsukeValue(name, before, id, index) {
    Swal.fire({
      title: '変更内容を入力',
      titleText: '変更前：' + before,
      input: 'text',
      inputValue: before,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: '登録',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#28A745',
      cancelButtonText: "キャンセル",

      inputValidator: (after) => {
        return new Promise((resolve) => {
          if (after.length < 1) {
            resolve('正しい内容を入力してください');
          } else {
            this.arrays[name][index][id] = after;
            // this.deleteItemArray(name, before);

            resolve(null);
          }
        })
      }
    }).then((result) => {
      if (environment.debug) {
        console.log(result);
      }
    })
  }

}
