import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-condition-icon',
  templateUrl: './condition-icon.component.html',
  styleUrls: ['./condition-icon.component.css']
})
export class ConditionIconComponent implements OnInit {
  @Input() condition: string | undefined = ""

  constructor(
  ) {

  }



  ngOnInit(): void {

  }
}
