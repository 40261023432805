import { User } from "../user/user";
import { Image } from "../image/image";
import * as moment from 'moment';
import { Observable } from "rxjs";
import { AngularFirestoreDocument } from "@angular/fire/firestore";

export class ChatMessage {

    sender: User;
    read: string[];
    image: any[];
    time: number;
    content: string;
    key: string;


    public readersRef: Observable<User>[] = [];

    constructor(user: User, content: string, read: string[], image: any[], time: number) {
        this.sender = user;
        this.read = read;
        this.time = time;
        this.image = image.concat();
        this.content = content;
    }

    setKey(key: string) {
        this.key = key;
    }



    setReadersRef(ref: AngularFirestoreDocument<User>) {
        this.readersRef.push(ref.valueChanges());
    }

    deserialize() { // 追加

        this.image = this.image ? this.image : null;
        return Object.assign({}, this);
    }


}
