

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import * as firebase from 'firebase/app';
import { MatTableDataSource } from "@angular/material/table";
import {
  TABLES,
  PeriodicElement,
  Transaction,
  Columns,
} from "../../assets/table-data";
import { Observable } from 'rxjs';
import { Userinfo } from 'src/app/services/userinfo';
import { Result } from '@zxing/library';


type Element = PeriodicElement | Transaction;
@Component({
  selector: 'read-qr-code',
  templateUrl: './read-qr-code.component.html',
  styleUrls: ['./read-qr-code.component.css']
})
export class ReadQrCodeComponent implements OnInit {
  public dataSource: MatTableDataSource<Element> =
    new MatTableDataSource<Element>();

  public columns: Columns[] = [];
  public rowColumns: string[] = [];
  public devices: any[] = [];
  public users: Observable<any[]>;

  onlyActiveDevice = true;

  dateForView = new Date();
  timeForView = new Date();

  numOfItemSlice = 1000000;

  sortHeaderNum = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
    private userInfo: Userinfo
  ) {
  }

  async onScanSuccess(result: Result): Promise<void> {
    const code = result.toString();
    console.log({scanCode: code});

    if(code.length !== 13) {
      return;
    }

    const id = 'test-' + code.replace(' ', '');

    console.log({scanId: id});

    const collection = firebase.default.firestore().collection('devices');
    await collection.doc(id).set({
      id,
      macAdress: id,
      wearerId: '',
    }, { merge: true});
  }

  onScanError(error: any): void {
    console.error('Scan error:', error);
    alert(error);
    // スキャンエラーを処理する
  }

  ngOnInit(): void {
    
  }
}