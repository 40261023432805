import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/class/user/user';
import { SessionService } from 'src/app/service/session.service';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Clinic } from 'src/app/class/clinic/clinic';
import { Patient } from 'src/app/class/patient/patient';
import { AngularFireStorage } from '@angular/fire/storage';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  user: User;

  public target_id: string;
  public targetUser: Observable<User>; // 追加
  public patients: Observable<Patient[]>;

  public clinics: Observable<Clinic[]>;

  constructor(
    private session: SessionService,
    private location: Location,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private alertViewService: AlertViewService

  ) {
    this.target_id = (this.route.snapshot.paramMap.get('id'));

    this.user = this.session.session.user;
    this.session // 追加
      .sessionState
      .subscribe(data => {
        if (environment.debug) {
          console.log(data);
        }
        this.user = data.user;
        this.clinics = db.collection<Clinic>('clinics', ref => { return ref.where('createdByVisitorList', '==', false) }).valueChanges();

        this.patients = db
          .collection<Patient>('patients', ref => { return ref.where('isboardroom', '==', false).where('members', 'array-contains-any', [this.user.uid]); }).valueChanges();



      });
    if (environment.debug) {
      console.log(this.user);
    }

    this.targetUser = db.doc<User>('users/' + this.target_id).valueChanges();



  }

  getClinicName(clinics, id) {
    if (id == undefined) return "";
    if (clinics != null) {
      return clinics.find(item => item.id === id) ? clinics.find(item => item.id === id).name : "";
    };
  }

  gotoMessageRoom(targetUser) {
    this.patients.pipe(first()).subscribe((patients) => {


      const ret = patients.find((patient => {
        return patient.members.includes(this.target_id)
      }));

      if (ret == undefined ? false : true) {
        this.router.navigate(['/chat/' + ret.patient_id + "/" + this.target_id]);
      } else {
        this.inviteChat(targetUser);
      }

    })
  }

  inviteChat(member: User) {
    const id = this.db.createId();
    if (environment.debug) {
      console.log(member);
    }
    const patient = new Patient(
      id,
      member.name,
      null,
      [this.user.uid, member.uid],
      null,
      false,
      "",
      "",
      []
    );
    patient.setUpdated(+moment());

    this.db.collection("patients").doc(id).set(patient.deserialize()).then(() => {
      this.alertViewService.notify("トークルームを追加しました");
      this.router.navigate(['/chat/' + id + "/" + this.target_id]);
    })



  }

  ngOnInit(): void {
    if (environment.debug) {
      console.log(this.user);
    }
  }

  back() {
    this.location.back();
  }


}
