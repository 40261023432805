


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';
import { ExportToCsv } from 'export-to-csv';
import { Image } from 'src/app/class/image/image';
import { User } from 'src/app/class/user/user';
import { Clinic } from 'src/app/class/clinic/clinic';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { SELECCTIONS_EVENT, SELECCTIONS_PATIENT } from 'src/app/class/selection/selection';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';
import { EChartsOption } from 'echarts';

import * as firebase from 'firebase/app';
import { Userinfo } from 'src/app/services/userinfo';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AngularFirestore, DocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';

interface Project {
  id: string,
  name: string,
  applicationId: string,
  apikey: string,
}

interface Device {
  projectId: DocumentSnapshot<Project>,
}

@Component({
  selector: 'app-summary-detail',
  templateUrl: './summary-detail.component.html',
  styleUrls: ['./summary-detail.component.css']
})
export class SummaryDetailComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.summarydetail)

  titles = this.lang.showArray(['熱中症リスク', '心拍モニタリング', '眠気モニタリング', '疲労モニタリング', '気象モニタリング', '自律神経モニタリング', '運転モニタリング', '感情モニタリング']);

  done = ['Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog', 'Walk dog', 'Walk dog', 'Walk dog'];



  list: Array<any>;



  public clinics: Observable<Clinic[]>;
  numOfCommentSlice = 40;

  related_members: User[] = [];

  searchText: string;



  public currentUser: User;

  shiborikomiWord = "";
  showOnlyUnread = false;

  macad = "";
  public device: Observable<any[]>;
  public myDevice: Device = {} as Device;

  public projects: Project[] = [];


  public observableArray = [];


  public initialDateIndex = 0;


  constructor(
    private router: Router,
    private alertViewService: AlertViewService,
    private localStorage: Storage,
    public userInfo: Userinfo,
    private route: ActivatedRoute,
    private db: AngularFirestore,
  ) {
    this.macad = (this.route.snapshot.paramMap.get('id'));
    this.userInfo.macAddress = (this.route.snapshot.paramMap.get('id'));
    this.userInfo.deviceType = "wearos";
    this.userInfo.startWatchingWearOSDatas();

    this.userInfo.getWeatherInfo();

    this.addObservable();

  }


  addObservable() {

    for (var i = 0; i < 5; i++) {
      var date = new Date();
      date.setDate(date.getDate() - this.initialDateIndex);
      var start = new Date(date.setHours(0, 0, 0, 0));
      var end = new Date(date.setHours(23, 59, 59, 0));

      console.log(start);
      console.log(end);


      this.observableArray.push(
        [moment(start).format(this.lang.show("YYYY 年 MM 月 DD 日")),
        this.db
          .collection<any>(this.macad, ref => {
            return ref.where('timestamp', '>=', start).where('timestamp', '<=', end).orderBy('timestamp', 'asc').limit(1);
          })
          .snapshotChanges()
          .pipe(
            map(actions => actions.map(action => {
              return action.payload.doc.data();
            })
            )
          ),
        this.db
          .collection<any>(this.macad, ref => {
            return ref.where('timestamp', '>=', start).where('timestamp', '<=', end).orderBy('timestamp', 'desc').limit(1);
          })
          .snapshotChanges()
          .pipe(
            map(actions => actions.map(action => {
              return action.payload.doc.data();
            })
            )
          ),]
      );

      this.initialDateIndex++;

    }

  }

  getFormatTime(value) {

    if (value == null || value.length == 0) return this.lang.show("データなし");

    // console.log(value[0].timestamp.toDate())
    const date = value[0].timestamp.toDate();

    return moment(date).format('HH:mm');
  }




  getObservableValue(value) {
    console.log(value);
  }




  getNettyuushoStr(num) {

    var ret = "";

    switch (num) {
      case 0:
        ret = "　　";
        break;
      case 1:
        ret = "　　";
        break;
      case 2:
        ret = this.lang.show("注意");
        break;
      case 3:
        ret = this.lang.show("危険");
        break;
      case 4:
        ret = this.lang.show("危険");
        break;
      case 101:
        ret = this.lang.show("危険");
        break;
      case 102:
        ret = this.lang.show("危険");
        break;
      case 103:
        ret = this.lang.show("危険");
        break;
      default:
        ret = "　　";
    }

    return ret;

  }

  checkOnGoingState() {
    if (this.userInfo.timestamp == undefined) return false;

    const itemDate = new Date(this.userInfo.timestamp["seconds"] * 1000).getTime();
    const currentDate = new Date().getTime();

    var difference = currentDate - itemDate;
    if (difference > 300000 || isNaN(itemDate)) {
      return false;
    } else {
      return true;
    }

  }
  replaceArrayElements(array, targetId, sourceId) {
    return array.reduce((resultArray, element, id, originalArray) => [
      ...resultArray,
      id === targetId ? originalArray[sourceId] :
        id === sourceId ? originalArray[targetId] :
          element
    ], []);
  }
  moveToUp(index) {
    if (index == 0) return;
    this.userInfo.titles = this.replaceArrayElements(this.userInfo.titles, index - 1, index);
    this.userInfo.setItemOrder(JSON.stringify(this.userInfo.titles));
  }

  moveToDown(index) {
    if (index == (this.userInfo.titles.length - 1)) return;
    this.userInfo.titles = this.replaceArrayElements(this.userInfo.titles, index + 1, index);
    this.userInfo.setItemOrder(JSON.stringify(this.userInfo.titles));
  }

  showSummary(index) {

    const ref = firebase.default.firestore().collection(this.macad + "-total").orderBy('time', 'desc').limit(1);
    ref.get().then(snapshot => {
      snapshot.forEach(doc => {
        const data = doc.data();
        this.alertViewService.showSummaryInfo(this.lang.show("疲労モニタリング(TP)"), this.lang.show("平均の平均: ") + data.averageOfAverage + "<br> " + this.lang.show("標準偏差の平均") + ": " + data.averageOfStd);
      })
    })
  }
  showPRSummary(index) {

    const ref = firebase.default.firestore().collection(this.macad + "-total").orderBy('time', 'desc').limit(1);

    ref.get().then(snapshot => {
      snapshot.forEach(doc => {
        const data = doc.data();
        console.log(data);
        this.alertViewService.showSummaryInfo(this.lang.show("心拍モニタリング(心拍数)"), this.lang.show("平均の平均: ") + data.averageOfAveragePR + "<br> " + this.lang.show("標準偏差の平均: ") + data.averageOfStdPR + "<br> 安静時心拍数: " + (data["平均安静時心拍数"] ? data["平均安静時心拍数"] : 75));
      })
    })
  }



  hideItem(name) {
    this.userInfo.titles = this.userInfo.titles.filter(e => e !== name);
    this.userInfo.setItemOrder(JSON.stringify(this.userInfo.titles));

  }

  ngOnInit() {
    console.log('ngOnInitです');



    this.device = this.db
      .collection<any>(this.macad, ref => {
        return ref.orderBy('timestamp', 'desc').limit(1);
      })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(action => {
          var data = action.payload.doc.data();
          this.myDevice = { ...data };

          data["time"] = data.timestamp.toDate();




          console.log(data);
          return data;
        })));

    this.device.subscribe();

    firebase.default.firestore().collection('projects')
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          this.projects.push(doc.data() as Project);
        });
      });

  }

  getWBGT(weather, isStatus) {

    var now = new Date();
    var indexNum = 0;

    if (weather == undefined) return this.lang.show("判定なし");

    weather["time"].forEach((item, index) => {
      var dateA = new Date(item);
      var diff = dateA.valueOf() - now.valueOf();
      if ((diff < (1000 * 60 * 30)) && (diff > (-1000 * 60 * 30))) {
        indexNum = index;
      }
    });

    var humidity = Math.round(weather["relativehumidity_2m"][indexNum] / 10) * 10;
    var temperature = parseInt(weather["temperature_2m"][indexNum]);


    var WBGTTable = {
      2040: 29,
      2039: 28,
      2038: 28,
      2037: 27,
      2036: 26,
      2035: 25,
      2034: 25,
      2033: 24,
      2032: 23,
      2031: 22,
      2030: 21,
      2029: 21,
      2028: 20,
      2027: 19,
      2026: 18,
      2025: 18,
      2024: 17,
      2023: 16,
      2022: 15,
      2021: 15,
      2540: 30,
      2539: 29,
      2538: 28,
      2537: 28,
      2536: 27,
      2535: 26,
      2534: 25,
      2533: 25,
      2532: 24,
      2531: 23,
      2530: 22,
      2529: 21,
      2528: 21,
      2527: 20,
      2526: 19,
      2525: 18,
      2524: 18,
      2523: 17,
      2522: 16,
      2521: 15,
      3040: 31,
      3039: 30,
      3038: 29,
      3037: 29,
      3036: 28,
      3035: 27,
      3034: 26,
      3033: 25,
      3032: 25,
      3031: 24,
      3030: 23,
      3029: 22,
      3028: 21,
      3027: 21,
      3026: 20,
      3025: 19,
      3024: 18,
      3023: 17,
      3022: 17,
      3021: 16,
      3540: 32,
      3539: 31,
      3538: 30,
      3537: 29,
      3536: 29,
      3535: 28,
      3534: 27,
      3533: 26,
      3532: 25,
      3531: 24,
      3530: 24,
      3529: 23,
      3528: 22,
      3527: 21,
      3526: 20,
      3525: 20,
      3524: 19,
      3523: 18,
      3522: 17,
      3521: 16,
      4040: 33,
      4039: 32,
      4038: 31,
      4037: 30,
      4036: 29,
      4035: 29,
      4034: 28,
      4033: 27,
      4032: 26,
      4031: 25,
      4030: 24,
      4029: 24,
      4028: 23,
      4027: 22,
      4026: 21,
      4025: 20,
      4024: 19,
      4023: 19,
      4022: 18,
      4021: 17,
      4540: 34,
      4539: 33,
      4538: 32,
      4537: 31,
      4536: 30,
      4535: 29,
      4534: 29,
      4533: 28,
      4532: 27,
      4531: 26,
      4530: 25,
      4529: 24,
      4528: 23,
      4527: 23,
      4526: 22,
      4525: 21,
      4524: 20,
      4523: 19,
      4522: 18,
      4521: 17,
      5040: 35,
      5039: 34,
      5038: 33,
      5037: 32,
      5036: 31,
      5035: 30,
      5034: 29,
      5033: 28,
      5032: 28,
      5031: 27,
      5030: 26,
      5029: 25,
      5028: 24,
      5027: 23,
      5026: 22,
      5025: 22,
      5024: 21,
      5023: 20,
      5022: 19,
      5021: 18,
      5540: 35,
      5539: 35,
      5538: 34,
      5537: 33,
      5536: 32,
      5535: 31,
      5534: 30,
      5533: 29,
      5532: 28,
      5531: 27,
      5530: 27,
      5529: 26,
      5528: 25,
      5527: 24,
      5526: 23,
      5525: 22,
      5524: 21,
      5523: 20,
      5522: 19,
      5521: 19,
      6040: 36,
      6039: 35,
      6038: 35,
      6037: 34,
      6036: 33,
      6035: 32,
      6034: 31,
      6033: 30,
      6032: 29,
      6031: 28,
      6030: 27,
      6029: 26,
      6028: 25,
      6027: 25,
      6026: 24,
      6025: 23,
      6024: 22,
      6023: 21,
      6022: 20,
      6021: 19,
      6540: 37,
      6539: 36,
      6538: 35,
      6537: 35,
      6536: 34,
      6535: 33,
      6534: 32,
      6533: 31,
      6532: 30,
      6531: 29,
      6530: 28,
      6529: 27,
      6528: 26,
      6527: 25,
      6526: 24,
      6525: 23,
      6524: 22,
      6523: 22,
      6522: 21,
      6521: 20,
      7040: 38,
      7039: 37,
      7038: 36,
      7037: 35,
      7036: 34,
      7035: 33,
      7034: 33,
      7033: 32,
      7032: 31,
      7031: 30,
      7030: 29,
      7029: 28,
      7028: 27,
      7027: 26,
      7026: 25,
      7025: 24,
      7024: 23,
      7023: 22,
      7022: 21,
      7021: 20,
      7540: 39,
      7539: 38,
      7538: 37,
      7537: 36,
      7536: 35,
      7535: 34,
      7534: 33,
      7533: 32,
      7532: 31,
      7531: 30,
      7530: 29,
      7529: 29,
      7528: 28,
      7527: 27,
      7526: 26,
      7525: 25,
      7524: 24,
      7523: 23,
      7522: 22,
      7521: 21,
      8040: 40,
      8039: 39,
      8038: 38,
      8037: 37,
      8036: 36,
      8035: 35,
      8034: 34,
      8033: 33,
      8032: 32,
      8031: 31,
      8030: 30,
      8029: 29,
      8028: 28,
      8027: 27,
      8026: 26,
      8025: 25,
      8024: 24,
      8023: 23,
      8022: 22,
      8021: 21,
      8540: 41,
      8539: 40,
      8538: 39,
      8537: 38,
      8536: 37,
      8535: 36,
      8534: 35,
      8533: 34,
      8532: 33,
      8531: 32,
      8530: 31,
      8529: 30,
      8528: 29,
      8527: 28,
      8526: 27,
      8525: 26,
      8524: 25,
      8523: 24,
      8522: 23,
      8521: 22,
      9040: 42,
      9039: 41,
      9038: 40,
      9037: 39,
      9036: 38,
      9035: 37,
      9034: 36,
      9033: 35,
      9032: 34,
      9031: 33,
      9030: 32,
      9029: 31,
      9028: 30,
      9027: 29,
      9026: 28,
      9025: 27,
      9024: 26,
      9023: 25,
      9022: 24,
      9021: 23,
      9540: 43,
      9539: 42,
      9538: 41,
      9537: 40,
      9536: 39,
      9535: 38,
      9534: 37,
      9533: 35,
      9532: 34,
      9531: 33,
      9530: 32,
      9529: 31,
      9528: 30,
      9527: 29,
      9526: 28,
      9525: 27,
      9524: 26,
      9523: 25,
      9522: 24,
      9521: 23,
      10040: 44,
      10039: 43,
      10038: 42,
      10037: 41,
      10036: 39,
      10035: 38,
      10034: 37,
      10033: 36,
      10032: 35,
      10031: 34,
      10030: 33,
      10029: 32,
      10028: 31,
      10027: 30,
      10026: 29,
      10025: 28,
      10024: 27,
      10023: 26,
      10022: 25,
      10021: 24
    }

    var humidity = Math.floor(humidity / 5) * 5;
    var num;

    if (temperature < 21) {
      num = 15;
    } else {
      num = WBGTTable[humidity.toString() + temperature.toString()];
    };

    var ret = "";

    if (num >= 31) {
      ret = isStatus ? this.lang.show("危険") : this.lang.show("運動は原則禁止");
    } else if (28 <= num && num <= 30) {
      ret = isStatus ? this.lang.show("厳重警戒") : this.lang.show("激しい運動は中止");
    } else if (25 <= num && num <= 27) {
      ret = isStatus ? this.lang.show("警戒") : this.lang.show("積極的に休憩");
    } else if (21 <= num && num <= 24) {
      ret = isStatus ? this.lang.show("注意") : this.lang.show("積極的に水分補給");
    } else if (15 <= num && num <= 20) {
      ret = isStatus ? this.lang.show("ほぼ安全") : this.lang.show("適宜水分補給");
    } else {
      ret = isStatus ? this.lang.show("ほぼ安全") : this.lang.show("適宜水分補給");
    }

    this.userInfo.wbgt = num;

    return ret;

  }

  getShimpakuState(isState) {
    // if (this.userInfo.datasAnsCar.filter((value) => {
    //   return value == 2;
    // }).length > (60 * 10 / 2)) {
    //   return isState ? "高心拍" : "10分以上高い心拍数が続いています";
    // }

    // if (this.userInfo.datasAnsCar.filter((value) => {
    //   return value == 3;
    // }).length > (60 * 10 / 2)) {
    //   return isState ? "低心拍" : "10分以上低い心拍数が続いています";
    // }

    // if (this.userInfo.datasNR.filter((value) => {
    //   return (value == 2 || value == 3);
    // }).length > (60 * 10 / 2)) {
    //   return isState ? "不整脈" : "不整脈の発生回数が多くなっています";
    // }


    // var value = this.getHeartRateJudge(this.userInfo.datasArrayPR10min, 100);
    // console.log("心拍計算");
    // console.log(value);
    if (this.getHeartRateJudge(this.userInfo.datasArrayPR10min, 100)) {
      return isState ? this.lang.show("高心拍") : this.lang.show("10分以上高い心拍数が続いています");
    } else if (this.getHeartRateJudge(this.userInfo.datasArrayPR10min, 50)) {
      return isState ? this.lang.show("低心拍") : this.lang.show("10分以上低い心拍数が続いています");
    }

    return "　　"
  }

  getHeartRateJudge(prHistory, threashold) {

    var count = 0;
    prHistory.forEach((item) => {
      if (item > threashold) {
        count = count + 1;
      }
    });



    if (count > (prHistory.length / 2)) {
      return true;
    }

    return false;
  }

  getShinpakuIcon() {
    if (this.getShimpakuState(true) === "　　") {
      return true;
    } else {
      return false;
    }
  }

  getJomakuStr(num) {

    var ret = "";

    switch (num) {
      case 0:
        ret = this.lang.show("判定なし");
        break;
      case 1:
        ret = this.lang.show("正常");
        break;
      case 2:
        ret = this.lang.show("高心拍");
        break;
      case 3:
        ret = this.lang.show("低心拍");
        break;
      default:
        ret = this.lang.show("判定なし");
    }

    return ret;

  }





  showLoading = true;
  onlyFirstTime = false;


  bindData(date) {
    if (this.showLoading) {
      this.alertViewService.showLoading();
      this.showLoading = false;
    }

    var start = new Date(date.setHours(0, 0, 0, 0));
    var end = new Date(date.setHours(23, 59, 59, 0));

    var timestampSubscriber: Subscription;

    if (timestampSubscriber) {
      timestampSubscriber.unsubscribe();
    }


    const MAXSIZE = 1000;

    timestampSubscriber = this.db.collection<any>(this.macad, ref =>
      ref.where('timestamp', '>', start)
        .where('timestamp', '<', end).orderBy('timestamp', 'desc').limit(MAXSIZE)
    ).snapshotChanges()
      .pipe(
        map((d) => {
          return d.map((action) => {
            const data = action.payload.doc.data({ serverTimestamps: "estimate" });
            return ({ id: action.payload.doc.id, ...data }) as any;
          });
        })
      ).subscribe(result => {

        if (result.length == 1) {
          return;
        }
        this.list = result;




        if (this.onlyFirstTime == false && this.list.length == MAXSIZE) {
          this.onlyFirstTime = true;
          this.checkListLength(this.macad, start, this.list[MAXSIZE - 1].timestamp.toDate(), MAXSIZE).then((ret) => {
            if (ret) {
              this.downloadCSV(date);
            }
          })
        } else {

          setTimeout(() => {
            this.alertViewService.dismiss();
            this.downloadCSV(date);
          }, this.list.length * 4);
        }


      });
  }


  anotherList = [];
  checkListLength(adress, start, end, size) {
    return new Promise<any>(async (resolve, reject) => {
      console.log("checkListLength");
      console.log(adress);
      console.log(start);
      console.log(end);
      console.log(size);
      var ref = this.db.collection<any>(adress, ref =>
        ref.where('timestamp', '>', start)
          .where('timestamp', '<', end).orderBy('timestamp', 'desc').limit(size));

      ref.valueChanges().pipe(take(1)).subscribe(ret => {
        const newList = ret.concat();
        console.log(newList);
        console.log(this.anotherList);
        this.anotherList = this.anotherList.concat(newList);
        if (ret.length > size - 15) {
          this.checkListLength(adress, start, this.anotherList[this.anotherList.length - 1].timestamp.toDate(), size).then((ret) => {
            if (ret) {
              resolve(true);
            } else {
              resolve(false);
            }
          })

        } else {
          console.log("sync successed!!");
          console.log(this.list);
          console.log(this.anotherList);
          this.alertViewService.dismiss();
          resolve(true);
        }
      })
    });
  }

  async assignProject() {
    const deviceSnapshot = (await this.db.collection('devices').doc(this.macad).get().toPromise());
    const device = deviceSnapshot.data() as Device;
    const projectId = device.projectId?.id ?? '';

    console.log(this.projects);

    const optionsListStr = (): string => {
      let html = `<option value="${this.lang.show('選択なし')}">`;
      this.projects.forEach((project: any) => {
        html = html + '<option value="' + project.id + '" ' + (projectId === project.id ? 'selected' : '') + '>' + project.name + '</option>';
      });

      return html;
    };

    const { value: formValues } = await Swal.fire({
      title: 'ユーザ情報を登録',
      html:
        '<div style="display: grid; grid-template-columns: 5rem 1fr;">' +
        `<div style="padding-top: 2rem;">${this.lang.show("プロジェクト")}</div><div><select id="swal-input1" class="swal2-input" style="width: -webkit-fill-available;">' + optionsListStr() + '</select></div>` +
        '</div>',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        const getValueById = (id: string): string => document.getElementById(id)["value"];

        const data = {
          project: getValueById('swal-input1'),
        };

        return data;
      },
    });


    if (formValues) {
      const newProjectId = formValues.project;
      if (newProjectId === this.lang.show("選択なし")) {
        this.db.collection('devices').doc(this.macad).update({
          projectId: firebase.default.firestore.FieldValue.delete()
        });
      } else {
        this.db.collection('devices').doc(this.macad).update({
          projectId: this.db.collection('projects').doc(newProjectId).ref,
        });
      }

      Swal.fire(this.lang.show("更新を完了しました"));
    }
  }

  showDatePicker() {
    this.alertViewService.showDatePicker().then((date: string) => {
      if (date != undefined && date !== "") {
        // this.downloadCSV(date);
        this.bindData(new Date(date));
      } else {
        this.showDatePicker();
      }
    });
  }

  downloadCSV(date) {


    this.alertViewService.showLoading();

    const markIfUndefinedOrEmpty = (text: string): string => {
      if (text === undefined) {
        return "";
      } else if (text === "") {
        return "";
      } else {
        return text;
      }
    }

    setTimeout(() => {

      new Promise<any>(async (resolve, reject) => {
        const options = {
          filename: "CSV" + this.macad.replace(/:/g, '') + "_" + moment(date).format('YYYYMMDD'),
          showTitle: true,
          title: "MACAdress" + this.macad.replace(/:/g, '') + ":Date" + moment(date).format('YYYY MM DD'),
          useKeysAsHeaders: true,
        };





        var drawlist = this.list.concat(this.anotherList)
        const output = drawlist.reverse().concat();
        var datas = [];
        output.forEach((item, index) => {


          const map = {
            time270ms: item["time270ms"] ?? "",
            timestamp: item.timestamp.toDate() ?? "",
            // savedDate: item["savedDate"].toDate() ?? "",
            // sensorcount: item["sensorcount"],
            deviceType: item["deviceType"] ?? "",
            AC2: item["AC2"] ?? "",
            Diff2LSB: item["Diff2LSB"] ?? "",
            AGStat: item["AGStat"] ?? "",
            BatteryStat: item["BatteryStat"] ?? "",
            BloodAge: item["BloodAge"] ?? "",
            BloodAgeRel: item["BloodAgeRel"] ?? "",
            SkinTemp: item["SkinTemp"] ?? "",
            temperature: item["temperature"] ?? "",
            PR: item["PR"] ?? "",
            RRI: item["RRI"] ?? "",
            RRIRel: item["RRIRel"] ?? "",
            wbgt: item["atsusa"] ?? "",
            HS: item["HS"] ?? "",
            NR: item["NR"] ?? "",
            // hirou: item["ttttttttt"]  ?? "",
            nemukedo: item["nemukedo"] ?? "",
            sumOfSlopeMPR: item["sumOfSlopeMPR"] ?? "",
            sumOfSlopeSkinTMP: item["sumOfSlopeSkinTMP"] ?? "",
            // kanjo: item["ttttttttt"]  ?? "",
            // BodyTemp: item["BodyTemp"]  ?? "",
            // Breath: item["Breath"]  ?? "",
            LF: item["LF"] ?? "",
            HF: item["HF"] ?? "",
            VLF: item["VLF"] ?? "",
            TP: item["TP"] ?? "",
            LFHF: item["LFHF"] ?? "",
            LFNorm: item["LFNorm"] ?? "",
            HFNorm: item["HFNorm"] ?? "",
            // HFTP: item["HFTP"]  ?? "",
            mPR: item["mPR"] ?? "",
            MRR: item["MRR"] ?? "",
            SDNN: item["SDNN"] ?? "",
            CVRR: item["CVRR"] ?? "",
            RMSSD: item["RMSSD"] ?? "",
            pNN50: item["pNN50"] ?? "",
            LP: item["LP"] ?? "",
            SD1: item["SD1"] ?? "",
            SD2: item["SD2"] ?? "",
            // a: item["ttttttttt"]  ?? "",
            // b: item["ttttttttt"]  ?? "",
            // c: item["ttttttttt"]  ?? "",
            // d: item["ttttttttt"]  ?? "",
            // e: item["ttttttttt"]  ?? "",
            AccX: item["AccX"] ?? "",
            AccY: item["AccY"] ?? "",
            AccZ: item["AccZ"] ?? "",
            gravityX: item["gravityX"] ?? "",
            gravityY: item["gravityY"] ?? "",
            gravityZ: item["gravityZ"] ?? "",
            // newX: item["newX"]  ?? "",
            // newY: item["newY"]  ?? "",
            // newZ: item["newZ"]  ?? "",
            // accelerometerWorldDataX: item["accelerometerWorldDataX"]  ?? "",
            // accelerometerWorldDataY: item["accelerometerWorldDataY"]  ?? "",
            // accelerometerWorldDataZ: item["accelerometerWorldDataZ"]  ?? "",
            directionAcc: item["directionAcc"] ?? "",
            directionSideAcc: item["directionSideAcc"] ?? "",
            vehicleStatus: item["vehicleStatus"] ?? "",
            drivescore: item["drivescore"] ?? "",
            // driveacc: item["driveacc"]  ?? "",
            // status: item["status"]  ?? "",
            longitude: item.location["longitude"] ?? "",
            latitude: item.location["latitude"] ?? "",
            accuracy: item["accuracy"] ?? "",
            speed: item["speed"] ?? "",
            convertedSpeed: item["newSpeed"] ?? "",
            heading: item["heading"] ?? "",
            altitude: item["altitude"] ?? "",
            altitudeAccuracy: item["altitudeAccuracy"] ?? "",
            // tenki: item["tenki"]  ?? "",
            // area: item["area"]  ?? "",
            // temp: item["temp"]  ?? "",
            // humid: item["humid"]  ?? "",
            pressure: item["pressure"] ?? "",
            steps: item["steps"] ?? "",
            HR: item["HR"] ?? "",
            idNum: item["idNum"] ?? "",
            ansCar: item["ansCar"] ? item["ansCar"] : 0,
            ansHR: item["ansHR"] ?? "",
            // tmpX: item["accelerometerWorldDataX"] ?? "",
            // tmpY: item["accelerometerWorldDataY"] ?? "",
            // tmpZ: item["accelerometerWorldDataZ"] ?? "",
            driveAlert: item["driveAlert"] ?? "",
            headAkeAlert: item["headAkeAlert"] ?? "",
            heartAlert: markIfUndefinedOrEmpty(item["heartAlert"]),
            nemukeAlert: markIfUndefinedOrEmpty(item["nemukeAlert"]),
            hirouAlert: markIfUndefinedOrEmpty(item["hirouAlert"]),
            thermAlert: markIfUndefinedOrEmpty(item["thermAlert"]),
            nettyusyoAlert: markIfUndefinedOrEmpty(item["nettyusyoAlert"]),
            shinpakuhaitei: markIfUndefinedOrEmpty(item["shinpakuhaitei"]),
            tenkiTemp: markIfUndefinedOrEmpty(item["tenkiTemp"]),
            tenkiHumidity: markIfUndefinedOrEmpty(item["tenkiHumidity"]),
          }




          datas.push(map);

        });

        const csvExporter = new ExportToCsv(options);


        this.alertViewService.dismiss();
        if (datas.length == 0) {
          this.alertViewService.showErrorAlert(this.lang.show("データ件数は0件です"));
        } else {



          csvExporter.generateCsv(datas);
          console.log(output);
        }
        this.onlyFirstTime = false;
        this.showLoading = true;
        this.anotherList = [].concat();


      });
    }, 1000)
  }


}
