import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByName'
})
export class SortByNamePipe implements PipeTransform {

  transform(items: any[], col: string, value: string): unknown {

    console.log(items);
    console.log(col);
    console.log(value);

    if (items == undefined) return items;

    return items.sort((a, b) => a.localeCompare(b));
  }

}
