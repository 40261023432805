import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterChat'
})
export class FilterChatPipe implements PipeTransform {

  transform(items: any[], userid: string, value: string, onlyUnread: boolean): any {

    console.log(items);
    console.log(userid);
    console.log(value);
    console.log(onlyUnread);

    return items.filter((x) => {


      if (onlyUnread) {
        if (!x.unReadMembers.includes(userid)) {
          return false;
        }
      }



      if (value == undefined) return true;

      if (x["主治医"].length > 0) {
        if (x["主治医"][0].indexOf(value) != -1) {
          return true;
        }
      }

      if (x["フリガナ"]) {
        return (x["name"].indexOf(value) != -1) || (this.kanaToHira(x["フリガナ"]).indexOf(value) != -1) || (this.hiraToKana(x["フリガナ"]).indexOf(value) != -1)
      } else {
        return (x["name"].indexOf(value) != -1)
      }
    });
  }

  kanaToHira(str) {
    return str.replace(/[\u30a1-\u30f6]/g, function (match) {
      var chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  }

  hiraToKana(str) {
    return str.replace(/[\u3041-\u3096]/g, function (match) {
      var chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  }


}
