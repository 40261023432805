


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { Storage } from '@ionic/storage-angular';
import { Userinfo } from 'src/app/services/userinfo';

import { AngularFirestore } from '@angular/fire/firestore';
import { getLangFromComponent, setOverrideTempLaunguage } from 'src/app/lang/logic';
import { ComponentLabel, LangType } from 'src/app/lang/dictionary';

@Component({
  selector: 'app-my-device',
  templateUrl: './my-device.component.html',
  styleUrls: ['./my-device.component.css']
})
export class MyDeviceComponent implements OnInit {
  macad = "";
  language: LangType = "en";
  lang = getLangFromComponent(ComponentLabel.conditiondetail);


  constructor(
    private router: Router,
    private alertViewService: AlertViewService,
    private localStorage: Storage,
    public userInfo: Userinfo,
    private route: ActivatedRoute,
    private db: AngularFirestore,
  ) {
    this.language = (this.route.snapshot.paramMap.get('lang')) as LangType;
    setOverrideTempLaunguage(this.language);
    this.macad = (this.route.snapshot.paramMap.get('id'));
  }

  showCondition() {

  }
  
  ngOnInit() {

  }
}
