import { User } from "../user/user";

export class Session { // 追加
    login: boolean;
    user: User; // 追加

    constructor() {
        this.login = false;
        this.user = new User(); // 追加
    }

    reset(): Session {
        this.login = false;
        this.user = new User(); // 追加
        return this;
    }
}


export class Password { // 追加
    name: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    picture: string;
    phone: string;
    clinic_id: string;
    occupation: string;
    clinic_name: string;

    constructor(object?: any) {
        if (object) {
            this.name = object.fullname;
            this.email = object.email;
            this.password = object.password;
            this.passwordConfirmation = object.confirmPassword;
            this.picture = object.picture;
            this.phone = object.phone;
            this.clinic_id = object.clinic_id;
            this.clinic_name = object.clinic_name;
            this.occupation = object.occupation;
        } else {
            this.name = '';
            this.email = '';
            this.password = '';
            this.passwordConfirmation = '';
            this.picture = '';
            this.phone = '';
            this.clinic_id = '';
            this.clinic_id = '';
            this.occupation = '';
        }
    }

    reset(): void {
        this.name = '';
        this.email = '';
        this.password = '';
        this.passwordConfirmation = '';
        this.picture = '';
        this.phone = '';
        this.clinic_id = '';
        this.occupation = '';

    }

    setClinicName(value) {
        this.clinic_name = value
    }
}