import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { User } from 'src/app/class/user/user';
import { SessionService } from 'src/app/service/session.service';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { Location } from '@angular/common'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  imageSrc: any;
  user: User;

  form: FormGroup;
  submitted = false;
  input_areas = "";

  constructor(
    private imageUploadService: ImageUploadService,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private session: SessionService,
    private alertViewService: AlertViewService,
    private location: Location,
  ) {
    this.user = this.session.session.user;
    this.session // 追加
      .sessionState
      .subscribe(data => {
        if (environment.debug) {
          console.log(data);
        }
        this.user = data.user;
        this.reloadFormValue();
      });


  }

  ngOnInit(): void {

    this.reloadFormValue();
  }

  compressFile() {
    this.imageUploadService.compressAndUploadFile().then((url) => {
      this.imageSrc = url;
    })
  }

  reloadFormValue() {
    this.form = this.formBuilder.group(
      {
        fullname: [this.user.name, Validators.required],
        phone: [this.user.tel, [Validators.required]],
        occupation: [this.user.occupation, Validators.required],
        summary: [this.user.summary],
      }
    );
    this.imageSrc = this.user.avatar;
  }






  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.form.value['occupation'] === 'フリー入力' && this.input_areas.length == 0) {
      return;
    }


    this.user.avatar = this.imageSrc ? this.imageSrc.replace("RoomsImages%2F", "RoomsImages%2Fthumb_") : "";
    this.user.name = this.form.value.fullname;
    this.user.tel = this.form.value.phone;
    this.user.occupation = this.form.value.occupation;
    if (this.form.value['occupation'] === 'フリー入力') {
      this.user.occupation = this.input_areas;
    }
    this.user.summary = this.form.value.summary;

    this.afs.collection('users').doc(this.user.uid).set(this.user).then(() => {
      this.alertViewService.notify("プロフィール情報を更新しました");
      this.back();
    })
  }

  back() {
    this.location.back();
  }

}
