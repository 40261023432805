


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertViewService } from 'src/app/services/alert-view.service';
import * as moment from 'moment';
import { Storage } from '@ionic/storage-angular';
import { DataZoomComponentOption, EChartsOption } from 'echarts';

import * as firebase from 'firebase/app';
import { Userinfo } from 'src/app/services/userinfo';
import { AngularFirestore } from '@angular/fire/firestore';
import DeviceRepository from 'src/app/repository/DeviceRepository';
import Num from 'src/app/repository/Num';
import { get } from 'http';
import ConditionRepository from 'src/app/repository/ConditionRepository';
import { HirouRepository } from 'src/app/repository/HirouRepository';
import { dateEqual } from 'src/app/repository/DateHelper';
import { getLangFromComponent, setOverrideTempLaunguage } from 'src/app/lang/logic';
import { ComponentLabel, LangType } from 'src/app/lang/dictionary';

enum ConditionType {
  Normal = "Normal",
  Fine = "Fine",
  Bad = "Bad",
  Nothing = "-",
}

interface DayCondition {
  date: Date,
  dateText: string,
  week: string,
  condition: string,
  shinpaku: string,
  shinpakuHendouScore: string,
  jiritsuShinkeiScore: string,
  nisshuseiHirou: string,
  strongHirou: string,
  nemukeScore: string,
  stressScore: string,
  riraxScore: string,
  nettyushoRisk: string,
  steps: string,
  maxTemp: string,
  minHumidity: string,
}

const getEmptyData = (date: Date): DayCondition => {
  const empty = "-"
  return {
    date,
    dateText: `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`,
    week: '日月火水木金土'.charAt(date.getDay()),
    condition: ConditionType.Nothing,
    shinpaku: empty,
    shinpakuHendouScore: empty,
    jiritsuShinkeiScore: empty,
    nisshuseiHirou: empty,
    strongHirou: empty,
    nemukeScore: empty,
    stressScore: empty,
    riraxScore: empty,
    nettyushoRisk: empty,
    steps: empty,
    maxTemp: empty,
    minHumidity: empty,
  };
}


const addDays = (date: Date, days: number): Date => new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
const getSevenDays = (startDate: Date): Date[] => [...Array(7)].map((_, i) => addDays(startDate, i));
const getThreeWeeks = (): Date[][] => [...Array(3)].map((_, i) => getSevenDays(addDays(new Date(), 1 - (i + 1) * 7)));

@Component({
  selector: 'app-my-condition',
  templateUrl: './my-condition.component.html',
  styleUrls: ['./my-condition.component.css']
})

export class MyConditionComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.conditiondetail);
  language: LangType = "en";

  tables: DayCondition[][] = getThreeWeeks().map(week => week.map(date => getEmptyData(date)));
  macAddress: string = '';
  sliceMacAddress: string = '';
  wearerName: string = '';
  device: any = null;
  subcate: any = null;



  constructor(
    private alertViewService: AlertViewService,
    private localStorage: Storage,
    private userInfo: Userinfo,
    private route: ActivatedRoute,
    private db: AngularFirestore,
  ) {
    this.language = (this.route.snapshot.paramMap.get('lang')) as LangType;
    setOverrideTempLaunguage(this.language);

    this.macAddress = (this.route.snapshot.paramMap.get('id'));
    this.sliceMacAddress = DeviceRepository.sliceMacAddress(this.macAddress);

    this.initialize();
  }

  async initialize() {
    const userDevice = await DeviceRepository.getDeviceSubCate(this.macAddress);
    this.device = userDevice.device;
    this.subcate = userDevice.subcate;
    this.wearerName = this.subcate?.name;
    this.setTable(this.macAddress);
  }

  getDayTableData (date: Date, mySammeries: any[], myTotals: any[]) {
    const yesterday = addDays(date, 0);
    const sammery = mySammeries.find(s => dateEqual(yesterday, s));
    const total = myTotals.find(t => dateEqual(yesterday, t));
    const defaultDate = getEmptyData(date);

    console.log({  date, sammery, total });

    const day: DayCondition = {
      ...defaultDate,
      condition: ConditionRepository.getCondition(total?.devLF, total?.devHF),
      shinpaku: sammery ? Num.toIntOrHyphen(sammery !== undefined ? sammery?.meanPR : null) : "-",
      shinpakuHendouScore: Num.toIntOrHyphen(sammery ? total?.devCVRR : null),
      jiritsuShinkeiScore: Num.toIntOrHyphen(sammery ? total?.devTP : null),
      nisshuseiHirou: sammery ? HirouRepository.getHirouMessage(date, mySammeries, myTotals) : "-",
      strongHirou: (sammery ? (sammery.isExistsStrongHirouAlert ? this.lang.show("あり") : this.lang.show("なし")) : "-"),
      nemukeScore: Num.toIntOrHyphen(sammery ? total?.devNemukedo : null),
      stressScore: Num.toIntOrHyphen(sammery ? total?.devLFHF : null),
      riraxScore: Num.toIntOrHyphen(sammery ? total?.devLP : null),
      nettyushoRisk: Num.toIntOrHyphen(sammery && sammery['maxAtsusa'] !== undefined ? (sammery['maxAtsusa'] < 28 ? "-" : sammery?.maxAtsusaRisukudo) : null),
      steps: Num.toIntOrHyphen(sammery?.steps),
      maxTemp: Num.toIntOrHyphen(sammery?.maxTemp),
      minHumidity: Num.toIntOrHyphen(sammery?.minHumidity),
    }

    return day
  }

  async setTable(macAddress: string) {
    const sammaries = (await firebase.default.firestore().collection(`${macAddress}-summary`).orderBy('time', 'desc').limit(22).get()).docs.map(d => d.data());
    const totals = (await firebase.default.firestore().collection(`${macAddress}-total`).orderBy('time', 'desc').limit(22).get()).docs.map(d => d.data());

    this.tables = getThreeWeeks().map(week => 
      week.map(date => this.getDayTableData(date, sammaries, totals)));
  }

  async addWeek() {
    const minDay = addDays(this.tables[this.tables.length - 1][0].date, - 7);
    const week = getSevenDays(minDay);

    const sammayCount = (this.tables.length + 1) * 7 +1;
    const sammaries = (await firebase.default.firestore().collection(`${this.macAddress}-summary`).orderBy('time', 'desc').limit(sammayCount).get()).docs.map(d => d.data());
    const totals = (await firebase.default.firestore().collection(`${this.macAddress}-total`).orderBy('time', 'desc').limit(sammayCount).get()).docs.map(d => d.data());

    this.tables = this.tables.concat([week.map(date => this.getDayTableData(date, sammaries, totals))]);
  }

  ngOnInit() {
  }
}

