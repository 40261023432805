import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChatMessage } from 'src/app/class/chat-message/chat-message';
import { User } from 'src/app/class/user/user';
import { Image } from 'src/app/class/image/image';
import { AngularFirestore } from '@angular/fire/firestore'; // 追加
import { Observable } from 'rxjs'; // 追加
import { finalize, first, map } from 'rxjs/operators'; // 追加
import { SessionService } from 'src/app/service/session.service';
import * as moment from 'moment';
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { Patient } from 'src/app/class/patient/patient';
import { take, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';

import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  public comments: Observable<ChatMessage[]>;
  public currentUser: User;
  public anotherUser: Observable<User> // 追加
  public patient_id: string
  public partner_id: string
  public patient: Observable<Patient>;
  imageSrc = [];

  @ViewChild('myEditable') private myEditable;


  // DI（依存性注入する機能を指定）
  constructor(
    private db: AngularFirestore,
    private session: SessionService,
    private location: Location,
    private route: ActivatedRoute,
    private imageUploadService: ImageUploadService,
    private storage: AngularFireStorage,
    private localStorage: Storage,
  ) {

    this.patient_id = (this.route.snapshot.paramMap.get('id'));
    this.partner_id = (this.route.snapshot.paramMap.get('partner'));

    this.putLocalStorageText();

    this.currentUser = this.session.session.user;
    this.session // 追加
      .sessionState
      .subscribe(data => {
        if (environment.debug) {
          console.log(data);
        }
        this.currentUser = data.user;
      });


    this.anotherUser = db.doc<User>('users/' + this.partner_id).valueChanges();


    this.comments = db.collection('patients').doc(this.patient_id)
      .collection<ChatMessage>('comments', ref => {
        return ref.orderBy('time', 'asc');
      })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(action => {
          // 日付をセットしたコメントを返す
          const data = action.payload.doc.data() as ChatMessage;
          const key = action.payload.doc.id;
          const commentData = new ChatMessage(data.sender, data.content, data.read, data.image, data.time);
          commentData.setKey(key)
          setTimeout(() => {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
          }, 1000)
          return commentData;
        })));



    this.patient = this.db
      .collection('patients')
      .doc(this.patient_id)
      .valueChanges()
      .pipe(

        switchMap((patient: Patient) => {

          const patientData = new Patient(
            patient.patient_id,
            patient.name,
            patient.avatar,
            patient.members,
            patient.clinic_id,
            patient.isboardroom,
            patient.disease_name,
            patient.birthday,
            patient.unReadMembers
          );

          if (patientData) {
            return of(patientData);
          } else {
            return of(null);
          }
        })
      );




  }

  ngOnInit(): void {
  }



  onChangeInput(evt) {
    const file: File = evt.target.files[0];

    // ファイル名を取得
    const fileName = file.name;

    // 保存先,ファイル名を指定
    const filePath = 'upload_files/' + fileName;
    const fileRef = this.storage.ref(filePath);

    this.storage.upload(filePath, file).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().pipe(first()).subscribe(url => {
          if (url) {
            this.imageSrc.push({
              name: fileName,
              url: url
            });
            console.log(url);
          }
        });
      })
    ).subscribe()
  }

  onChangeTextInput(evt) {

    console.log(evt);
    this.localStorage.set(this.patient_id, evt);

  }

  putLocalStorageText() {

    this.localStorage.get(this.patient_id).then((text) => {
      this.myEditable.nativeElement.innerText = text;
    })


  }



  // 新しいコメントを追加
  addComment(patient: Patient) {
    var comment = this.myEditable.nativeElement.innerText;


    if (comment || this.imageSrc.length) {


      this.db.collection('patients').doc(this.patient_id)
        .update({
          unReadMembers: patient.members.filter(member => { return member !== this.currentUser.uid }),
          updated: +moment()
        }).then(() => {
          this.db.collection('patients').doc(this.patient_id)
            .collection('comments')
            .add(new ChatMessage(this.currentUser, comment, [this.currentUser.uid], this.imageSrc, +moment()).deserialize()); // 更新
          this.myEditable.nativeElement.innerText = '';
          this.imageSrc = [];
        })




      setTimeout(() => {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }, 1000)
    }

  }


  showFile(url) {

    window.open(url, '_blank', 'location=yes');
  }
  read(key) {
    this.db.collection('patients').doc(this.patient_id)
      .collection('comments').doc(key)
      .update({
        read: firebase.default.firestore.FieldValue.arrayUnion(this.currentUser.uid)
      });


    this.hasReadAllMessages().then((ret) => {
      if (ret) {
        this.db.collection('patients').doc(this.patient_id)
          .update({
            unReadMembers: firebase.default.firestore.FieldValue.arrayRemove(this.currentUser.uid)
          });
      }
    })


  }



  hasReadAllMessages() {
    return new Promise((resolve) => {
      this.comments.pipe(first()).subscribe((comments) => {
        const ret = comments.find((comment => {
          return !comment.read.includes(this.currentUser.uid)
        }));
        resolve(ret == undefined ? true : false);
      })
    })
  }




  countReader(readers, uid) {
    return readers.filter(function (x) { return x === uid }).length;
  }

  back() {
    this.location.back();
  }

  changedText() {

  }



}
