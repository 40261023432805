import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Clinic } from 'src/app/class/clinic/clinic';
import { Event } from 'src/app/class/event/event';
import { Patient } from 'src/app/class/patient/patient';
import { User } from 'src/app/class/user/user';
import { SessionService } from 'src/app/service/session.service';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {

  public patients: Observable<Patient[]>;
  public events: Observable<Event[]>;
  public currentUser: User;
  searchText: string;

  numOfCommentSlice = 20;


  constructor(
    private router: Router,
    private db: AngularFirestore,
    private session: SessionService,

    private alertViewService: AlertViewService,

  ) {
    this.currentUser = this.session.session.user;
    this.session // 追加
      .sessionState
      .subscribe(data => {
        if (environment.debug) {
          console.log(data);
        }
        this.currentUser = data.user;

        this.patients = db
          .collection<Patient>('patients', ref => { return ref.where('members', 'array-contains', this.currentUser.uid).where('isboardroom', '==', true); })
          .snapshotChanges()
          .pipe(
            map(actions => actions.map(action => {
              // 日付をセットしたコメントを返す
              const data = action.payload.doc.data() as Patient;
              const patientData = new Patient(
                data.patient_id,
                data.name,
                data.avatar,
                data.members,
                data.clinic_id,
                data.isboardroom,
                data.disease_name,
                data.birthday,
                data.unReadMembers
              );
              if (environment.debug) {
                console.log(patientData)
              }
              patientData.setClinicRef(db.doc<Clinic>('clinics/' + patientData.clinic_id));
              patientData.setLatestEventRef(db.collection('patients').doc(data.patient_id).collection<Event>('events', ref => { return ref.where('clinicId', '==', this.currentUser.clinic_id).where('date', '>=', Date.now()).orderBy('date', 'desc').limit(1); }));
              patientData["フリガナ"] = data["フリガナ"] ? data["フリガナ"] : "";

              return patientData;
            })

            ))

        this.events = db
          .collectionGroup<Event>('events', ref => { return ref.where('clinicId', '==', this.currentUser.clinic_id).where('date', '>=', Date.now()).orderBy('date', 'asc').limit(30); })
          .snapshotChanges()
          .pipe(
            map(actions => actions.map(action => {
              // 日付をセットしたコメントを返す
              const data = action.payload.doc.data();
              data["patient_id"] = action.payload.doc.ref.parent.parent?.id

              return data;
            })

            ))








      });
    console.log(this.session.session.user.uid);




  }

  sortByName(a, b) {


    if (a.name < b.name)
      return -1;
    if (a.name > b.name)
      return 1;
    return 0;




  }

  releaseNumOfList() {
    this.numOfCommentSlice = this.numOfCommentSlice + 10;
  }

  compareFn = (a, b) => {
    if (a.unwrappedName < b.unwrappedName)
      return -1;
    if (a.unwrappedName > b.unwrappedName)
      return 1;
    return 0;
  };

  formatDate(num) {
    if (num >= 999999999999999999) return "";
    moment.lang('ja');
    return moment(num).format('LLL');
  }



  disablePatient(event, patient) {
    this.alertViewService.showYesNoAlertForPatientDelete(patient.name).then((ret) => {
      if (ret) {

        this.db.collection("patients").doc(patient.patient_id).update({
          members: firebase.default.firestore.FieldValue.arrayRemove(this.currentUser.uid)
        }).then(() => {
          this.alertViewService.notify(patient.name + "さんを非表示にしました");
        });

      }
    })
    event.stopPropagation();




  }


  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  ngOnInit(): void {

  }

  shouldShowUnReadBadge(patient) {
    return patient.unReadMembers.includes(this.currentUser.uid);
  }


  receiveSearchText(event) {
    if (environment.debug) {
      console.log(event);
    }
    this.searchText = event;
  }

}
